import React, { Component } from 'react'

class Waiting extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <svg
          className="introLoader"
          width="519"
          height="89"
          viewBox="0 0 519 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{margin:'20px'}}
        >
          <path
            d="M70.586 11.986V0.792994H0.23V11.986H29.381V88H41.435V11.986H70.586ZM151.495 88V0.792994H139.441V38.062H94.915V0.792994H82.861V88H94.915V49.255H139.441V88H151.495ZM226.683 88V76.807H185.724V50.116H222.87V38.8H185.724V11.986H226.683V0.792994H173.67V88H226.683ZM329.148 88V76.684H287.943V0.792994H275.889V88H329.148ZM406.07 88H419.108L384.422 0.792994H370.523L335.837 88H348.752L357.977 63.646H396.599L406.07 88ZM377.165 13.339L392.294 52.453H362.282L377.165 13.339ZM431.441 0.792994V88H461.945C477.935 88 488.882 78.037 488.882 64.261C488.882 54.544 483.101 45.688 473.138 43.228C480.764 40.399 486.176 33.757 486.176 24.409C486.176 10.633 476.459 0.792994 459.854 0.792994H431.441ZM443.495 38.923V11.371H458.009C467.972 11.371 473.999 16.783 473.999 25.024C473.999 33.142 468.218 38.923 458.501 38.923H443.495ZM443.495 77.422V49.255H460.715C471.293 49.255 476.582 55.159 476.582 63.523C476.582 72.01 469.94 77.422 460.223 77.422H443.495ZM501.172 80.128C501.172 84.925 504.862 88.615 509.659 88.615C514.456 88.615 518.269 84.925 518.269 80.128C518.269 75.331 514.456 71.518 509.659 71.518C504.862 71.518 501.172 75.331 501.172 80.128Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="-1"
              y1="42.8041"
              x2="515"
              y2="43"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FDD86A" />
              <stop offset="0.333333" stopColor="#52F2D3" />
              <stop offset="0.635417" stopColor="#8D76DA" stopOpacity="0.9" />
              <stop offset="1" stopColor="#FB12BE" stopOpacity="0.33" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    )
  }
}

export default Waiting
