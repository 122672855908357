import React, { Component, useState } from 'react'

import { connect } from 'react-redux'

import { onFormationChange } from '../../../admin/actions/playerActions'

class EditableArea extends Component {
  constructor(props) {
    super(props)

    this.state = { text: props.field }
  }

  onInput = (e) => {
    if (this.props.onInputChange) {
      var res = this.props.onInputChange(e.currentTarget.innerHTML)
      if (!res.index) res.index = this.props.index
      this.props.onFormationChange(
        this.props.type,
        res.data,
        res.index,
        this.props.isLanding ? this.props.landing : this.props.formation,
        this.props.isLanding ? true : false
      )
    } else {
      this.props.onFormationChange(
        this.props.type,
        e.currentTarget.innerHTML,
        this.props.index,
        this.props.isLanding ? this.props.landing : this.props.formation,
        this.props.isLanding ? true : false
      )
    }
  }
  onBlur = (e) => {
    this.onInput(e)
    this.setState({ text: e.currentTarget.innerHTML })
  }

  render() {
    const Tag = `${this.props.tag}`
    if (this.props.isEditor) {
      return (
        <Tag
          contentEditable
          onInput={this.onInput}
          onBlur={this.onBlur}
          className={this.props.classTag}
          dangerouslySetInnerHTML={{ __html: this.state.text }}
        ></Tag>
      )
    } else {
      return (
        <Tag
          className={this.props.classTag}
          dangerouslySetInnerHTML={{ __html: this.props.field }}
        ></Tag>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  formation: state.playerFormation,
  landing: state.playerLanding,
  index: state.playerIndex,
})

export default connect(mapStateToProps, { onFormationChange })(EditableArea)
