import React, { useEffect, useContext, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logoutUser } from '../../actions/authActions'

import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

import LanguageContext from "../../contexts/LanguageContext"

import { Box } from '@material-ui/core'

import Layout from "../mainLayout/Layout"

import frenchLanguage from '../../img/frenchLanguage.png';
import englishLanguage from '../../img/englishLanguage.png';
import { languages } from "./Register"


const Logout = (props) => {
  const languageContext = useContext(LanguageContext);

  const languageRef = useRef(null)

  useEffect(() => {
    console.log("logout")
    props.logoutUser()
    props.history.push("/")
  }, [])


  return (
    <Layout componentsDown={
      <></>
    }>
      <Box sx={{marginBottom:"30px", fontSize: "25px", fontWeight:"bold",}}>
        {languageContext.values.logout.title[languageContext.language]}
      </Box>
    </Layout>
  )
}



Logout.propTypes = {
  auth: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  auth: state.auth,
})
export default connect(mapStateToProps, { logoutUser })(Logout)
