import Format from './Format.js'
import MaskQuizz from '../helpers/MaskQuizz.js'
import Button from '../Button'
import Donut from '../helpers/Donut'
import React, { Component } from 'react'

import badCheck from '../../../img/badCheck.svg'
import goodCheck from '../../../img/goodCheck.svg'

import EditableField from '../helpers/EditableField.js'
import EditableArea from '../helpers/EditableArea.js'
import FormatStepEditor from '../../../admin/components/formation/player/FormatStepEditor.js'
import Dropdown from '../helpers/Dropdown.js'
import Toggle from '../helpers/Toggle.js'

class FormatFeedback extends Format {
  constructor(props) {
    super(props)
    var feedback = props.format.userChoice ? props.format.userChoice : this.getFeedback()
    this.state = {
      formats: props.userContent,
      feedback: feedback,
      isMobile: window.innerWidth <= 600,
    }

  }

  getFeedback = (actualIndex, partie) => {
    if (this.props.isLanding === true) { //landing
      var data = { questions: 0, quizz: 0, goodAnser: 0, isGood: true }
      function manageFormat(data, format) {
        if (['Questionnaire', 'Quizz'].includes(format.type)) data.questions += 1
        if (['Quizz'].includes(format.type) && format.userIsValid !== 0) {
          data.quizz += 1
          if (format.userIsValid === true) data.goodAnser += 1
        }
        return data
      }

      var indexSeq = this.props.index - 1
      while (indexSeq >= 0 && this.props.userContent[indexSeq].type !== 'Feedback') {
        var data = manageFormat(data, this.props.userContent[indexSeq])
        indexSeq -= 1
      }

      if (data.quizz > 0 && data.goodAnser >= Math.trunc(data.quizz / 2)) data.isGood = false
      this.props.onDataChange([{ key: 'userChoice', value: data }])

      return data
    } else { //formation
      var data = { questions: 0, quizz: 0, goodAnser: 0, isGood: true }
      function manageFormat(data, format) {
        if (['Questionnaire', 'Quizz'].includes(format.type)) data.questions += 1
        if (['Quizz'].includes(format.type) && format.userIsValid !== 0) {
          data.quizz += 1
          if (format.userIsValid === true) data.goodAnser += 1
        }
        return data
      }

      if (this.props.format.isChapter) {
        for (var i in this.props.chapitre.partie) {
          for (var n in this.props.chapitre.partie[i].sequence) {
            var data = manageFormat(data, this.props.chapitre.partie[i].sequence[n])
          }
        }
      } else {
        var indexSeq = this.props.index - 1
        while (indexSeq >= 0 && this.props.userContent[indexSeq].type !== 'Feedback') {
          var data = manageFormat(data, this.props.userContent[indexSeq])
          indexSeq -= 1
        }
      }

      if (data.quizz > 0 && data.goodAnser >= Math.trunc(data.quizz / 2)) data.isGood = false
      this.props.onDataChange([{ key: 'userChoice', value: data }])

      return data
    }
  }

  switchAdminData = (isGood) => {
    var feedback = this.state.feedback
    feedback.isGood = isGood
    this.setState((prevstate) => ({ feedback: feedback }))
  }

  onToggle = (checked) => {
    this.setState({ feedback: this.getFeedback() })
  }

  render() {
    if (!this.props.format.subTitle) {
      this.props.format.subTitle = 'Questions répondues'
    }
    if (!this.props.format.text) {
      this.props.format.text = 'Bonnes réponses'
    }

    var res = this.state.feedback
    return (
      <div className="format_quizz format_feedback formatBox">
        <div className="format_wrapper">
          <div></div>
          <div>
            <div className="main_wrapper">
              {this.props.isEditor || this.props.format.exerciceTitle ? (
                <EditableField
                  isLanding={this.props.isLanding}
                  tag="h1"
                  classTag="xs"
                  field={this.props.format.exerciceTitle}
                  isEditor={this.props.isEditor}
                  type="exerciceTitle"
                />
              ) : (
                ''
              )}
              <EditableField
                isLanding={this.props.isLanding}
                tag="h2"
                field={this.props.format.title}
                isEditor={this.props.isEditor}
                type="title"
              />
              <EditableArea
                tag="div"
                field={res.isGood ? this.props.format.success : this.props.format.fail}
                isEditor={this.props.isEditor}
                isLanding={this.props.isLanding}
                type={res.isGood ? 'success' : 'fail'}
                key={res.isGood ? 'area1Succes' : 'area1Fail'}
              />

              {this.state.isMobile && (
                <MaskQuizz className="maskQuizz">
                  <>
                    <Donut
                      title={this.props.format.responses.good}
                      i={null}
                      total={res.questions}
                      name="donut79Mo"
                      isEditor={this.props.isEditor}
                      isLanding={this.props.isLanding}
                    />
                    {res.quizz > 0 && (
                      <Donut
                        title={this.props.format.responses.bad}
                        i={res.goodAnser}
                        total={res.quizz}
                        name="donutQuizz"
                        isEditor={this.props.isEditor}
                        isLanding={this.props.isLanding}
                      />
                    )}
                  </>
                </MaskQuizz>
              )}
            </div>

            <div className="btn_wrapper">
              <Button
                key="txt_btn_next"
                isEditor={this.props.isEditor}
                isLanding={this.props.isLanding}
                btn={this.props.format.button}
                callback={() => {
                  this.manageResponse()
                }}
              />
            </div>
          </div>
          <div></div>
        </div>
        {!this.state.isMobile && (
          <MaskQuizz className="maskQuizz">
            <>
              <Donut
                title={this.props.format.subTitle}
                i={null}
                total={res.questions}
                name="donut79Mo"
                isEditor={this.props.isEditor}
                isLanding={this.props.isLanding}
                editableType="subTitle"
              />
              {res.quizz > 0 && (
                <Donut
                  title={this.props.format.text}
                  i={res.goodAnser}
                  total={res.quizz}
                  name="donutQuizz"
                  isEditor={this.props.isEditor}
                  isLanding={this.props.isLanding}
                  editableType="text"
                />
              )}
            </>
          </MaskQuizz>
        )}
        {this.props.isEditor && (
          <>
            <FormatStepEditor
              steps={[
                {
                  img: goodCheck,
                  callback: () => {
                    this.switchAdminData(true)
                  },
                },
                {
                  img: badCheck,
                  callback: () => {
                    this.switchAdminData(false)
                  },
                },
              ]}
            />
            <Dropdown>
              <div className="dropdown_item">
                <Toggle
                  isLanding={this.props.isLanding}
                  type="isChapter"
                  check={this.props.format.isChapter}
                  label="Feedback de chapitre"
                  callback={this.onToggle}
                />
              </div>
              <div className="dropdown_item">
                <h5>Type Score:</h5>
                <EditableField
                  isLanding={this.props.isLanding}
                  tag="p"
                  field={this.props.format.scoreType}
                  isEditor={true}
                  type="scoreType"
                />
              </div>
              <div className="dropdown_item">
                <h5>Skill:</h5>
                <EditableField
                  isLanding={this.props.isLanding}
                  tag="p"
                  field={this.props.format.skill}
                  isEditor={true}
                  type="skill"
                />
              </div>
            </Dropdown>
          </>
        )}
      </div>
    )
  }
}

export default FormatFeedback
