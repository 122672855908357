import React, { Component, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { initData } from './actions/formationActions'
import { connect } from 'react-redux'

import store from './utils/store'
import { setCurrentUser, getCurrentUser, authStatus, logoutUser } from './actions/authActions'

import { GLB_URL_APIS } from './actions/types'

/*import HomePage from './components/homepage/HomePage'
import PricePage from './components/homepage/PricePage'*/

import PrivateRoute from './routes/PrivateRoute'
import PrivateRouteClientAdmin from './routes/PrivateRouteClientAdmin'
import Register from './components/auth/Register'
import RegisterAdmin from './components/auth/RegisterAdmin'
import Login from './components/auth/Login'
import ErrorLogin from './components/auth/ErrorLogin'
import Logout from './components/auth/Logout'
import LandingPage from './components/landingPage/LandingPage'
import Waiting from './components/layout/Waiting'
import AdminRoute from './routes/AdminRoute'

import LanguageContext from "./contexts/LanguageContext"

const ResetPassword = lazy(() => import('./components/auth/ResetPassword'))
const NoMobile = lazy(() => import('./components/layout/NoMobile'))
const Chrome = lazy(() => import('./components/layout/Chrome'))
const Dashboard = lazy(() => import('./components/dashboard/Dashboard'))
const Formation = lazy(() => import('./components/formation/Formation'))
const MyAccount = lazy(() => import('./components/dashboard/MyAccount'))

const TermsFR = lazy(() => import('./components/docs/TermsFR'))
const TermsEN = lazy(() => import('./components/docs/TermsEN'))
const DatapolicyFR = lazy(() => import('./components/docs/DatapolicyFR'))
const DatapolicyEN = lazy(() => import('./components/docs/DatapolicyEN'))

const AdminDashboard = lazy(() => import('./components/adminDashboard/AdminDashboard'))

const PdfPage = lazy(() => import('./components/pdf/PdfPage'))

class App extends Component {
  constructor(props) {
    super(props)

    this.state = { authenticated: false }

    this.isLoadingAllInfos = false;

    if (getCurrentUser()?.id) {
      store.dispatch(setCurrentUser(getCurrentUser()))
      props.authStatus()
    } else {
      store.dispatch(setCurrentUser({}))
      props.authStatus()
    }

  }

  render() {
    if (this.props.auth.isAuthenticated) {
      this.isLoadingAllInfos = false;
    }

    if (this.props.auth.isAuthenticated && !this.props.auth.formationLoaded) {
      if (this.isLoadingAllInfos === false) {
        this.isLoadingAllInfos = true;
        this.props.initData()
      }
    }

    if (this.props.auth.isAuthenticated) {
      if (!this.props.user.language) {
        return <Waiting />
      }
    }

    if (this.context?.isLoading === true) {

      if (!this.props.auth.isAuthenticated || this.props.auth.formationLoaded) {
        return (
          <Router>
            <Suspense fallback={<Waiting />}>
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>

              <Route exact path="/page/:slug" component={LandingPage} />

              <Route exact path="/terms_FR" component={TermsFR} />
              <Route exact path="/terms_EN" component={TermsEN} />
              <Route exact path="/datapolicy_FR" component={DatapolicyFR} />
              <Route exact path="/datapolicy_EN" component={DatapolicyEN} />

              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/errorLogin" component={ErrorLogin} />
              <Route exact path="/mdp-perdu" component={ResetPassword} />
              <Route exact path="/chrome" component={Chrome} />

              <Route exact path="/pdf" component={PdfPage} />

              <Route exact path="/registerAdmin" component={RegisterAdmin} />

              <Switch>
                <PrivateRoute exact path="/nomobile" component={NoMobile} />

                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/formation" component={Formation} />
                <PrivateRoute exact path="/icebreaker" component={Formation} />
                <PrivateRoute exact path="/myaccount" component={MyAccount} />

                <PrivateRouteClientAdmin path="/admindashboard" component={AdminDashboard} />
                <PrivateRouteClientAdmin path="/myadminaccount" component={MyAccount} />
                <AdminRoute />
              </Switch>
            </Suspense>
          </Router>
        )
      } else {
        return <Waiting />
      }

    } else {
      return <Waiting />
    }
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user : state.user,
  }
}

export default connect(mapStateToProps, { initData, authStatus })(App)
App.contextType = LanguageContext
