import axios from 'axios'

import {
  FETCH_FORMATION,
  SET_COLOR,
  ACTUAL_INDEX,
  GET_ERRORS,
  PROGRESSION,
  DATES,
  USER,
  ADMINGROUPES,
  PLAN,
  USER_FORMATION_LOADED,
  GLB_URL_APIS,
} from './types'

let jsondiffpatch = require("jsondiffpatch");

const editFormationResult = async (elementID, type, vals) => {
  let values = {
    elementID : elementID,
    vals : vals
  }
  try {
    const res = await axios.post(GLB_URL_APIS + "/formation/"+type+"/saveResult", values, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}

async function requestApi(url, data) {
  var options = { headers: { 'Content-Type': 'application/json' } }

  return await axios.post(GLB_URL_APIS + '/formation' + url, data, options)
}

const sequencesDataRefacto = (seq) => {
  if (seq.userChoice && seq.userChoice !== null) {
    try {
      seq.userChoice = JSON.stringify(seq.userChoice)
    } catch(e) {
      seq.userChoice = seq.userChoice
    }
  }

  return seq
}

export const saveUserFormation = (data) => async (dispatch) => {
  data.progress = calculateProgress(data)
  let actFormation = JSON.parse(JSON.stringify(data))
  let compareForSave = JSON.parse(sessionStorage.getItem('saveFormation'))


  actFormation.chapitre.forEach((chap, indexChap) => {
    chap.partie.forEach((part, indexPart) => {
      part.sequence.forEach((seq, indexSeq) => {
        seq = sequencesDataRefacto(seq)
      })
    })
  })


  compareForSave.chapitre.forEach((chap, indexChap) => {
    chap.partie.forEach((part, indexPart) => {
      part.sequence.forEach((seq, indexSeq) => {
        seq = sequencesDataRefacto(seq)
      })
    })
  })

  var diffpatcher = jsondiffpatch.create({
    cloneDiffValues: true,
    propertyFilter: function(name, context) {
      if (name !== 'color') {
        return name;
      }
    },
    objectHash: function(obj, index) {
      return obj.id || '$$index:' + index;
    },
    arrays: {
        // default true, detect items moved inside the array (otherwise they will be registered as remove+add)
        detectMove: true,
        // default false, the value of items moved is not included in deltas
        includeValueOnMove: false,
    },
    textDiff: {
        minLength: 99999999999999,
    },
  });
  let delta = diffpatcher.diff(compareForSave, actFormation);
  if (delta) {
    let deltaFormat = jsondiffpatch.formatters.jsonpatch.format(delta)
    //console.log(deltaFormat)
    let changesArray = []
    //console.log(deltaFormat)
    deltaFormat.forEach((change) => {
      if (change.op === "replace") {
        let infosUpdate = deltaPathToElementReplace(actFormation, change.path)
        //console.log(infosUpdate)
        //console.log("change "+infosUpdate.type+" with resultID "+infosUpdate.el.resultID+", key "+infosUpdate.key+" to "+change.value)
        changesArray.push(async function() {
          return await editFormationResult(
            infosUpdate.el.id, //elementID (content)
            //infosUpdate.el.resultID, //resultID
            infosUpdate.type,
            {
              [infosUpdate.key] : change.value
            }
          )
        })
      }
    });


    if (changesArray.length > 0) {
      //console.log(changesArray)
      const results = await Promise.all(changesArray.map(f => f()));
      sessionStorage.setItem('saveFormation', JSON.stringify(data));
    }
  }
  //dispatch({ type: FETCH_FORMATION, data: data })

}

const deltaPathToElementReplace = (formation, path) => {
  let pathArr = path.substring(1).split('/')
  let keyToChange = pathArr[pathArr.length - 1]
  let element = {...formation};

  for (let i=0; i<pathArr.length-1; i++) {
    element = element[pathArr[i]]
  }

  return ({el : element, type : pathArr[pathArr.length-3] || 'content', key : keyToChange})
}

export const naviguateFormation = (iChap, iPart, iSequence) => (dispatch) => {
  dispatch({
    type: ACTUAL_INDEX,
    data: { chapitre: iChap, partie: iPart, sequence: iSequence },
  })
}

export const initData =
  ($id = null) =>
  async (dispatch) => {
    var res = await requestApi('/getAllInfos', { id: $id })
    if (!res || !res.data) return console.log('ERREUR INIT DATA')

    if (res.data.plan) dispatch({ type: PLAN, data: res.data.plan })
    if (res.data.formation) {
      sessionStorage.setItem('saveFormation', JSON.stringify(res.data.formation));
      let lastIndex = calculateLastIndex(res.data.formation)
      dispatch({ type: ACTUAL_INDEX, data: lastIndex })
      dispatch({ type: FETCH_FORMATION, data: res.data.formation })
      dispatch({ type: SET_COLOR, data: res.data.formation.color })
    } else {
      console.log("pas de formation")
      dispatch({ type: FETCH_FORMATION, data: null })
    }
    if (res.data.user) {
      dispatch({ type: USER, data: res.data.user })
      if (res.data.groupes) {
        dispatch({ type: ADMINGROUPES, data: res.data.groupes })
      }
      dispatch({ type: DATES, data: res.data.user.dates })
      dispatch({ type: USER_FORMATION_LOADED })
    }
  }

function calculateLastIndex(formation) {
  if (formation.isDone) return { chapitre: 'max', partie: 0, sequence: 0 }

  for (var iChap = 0; iChap < formation.chapitre.length; iChap++) {
    var chapitre = formation.chapitre[iChap]
    for (var iPart = 0; iPart < chapitre.partie.length; iPart++) {
      var partie = chapitre.partie[iPart]
      for (var iSeq = 0; iSeq < partie.sequence.length; iSeq++) {
        if (partie.sequence[iSeq].isDone) continue
        return { chapitre: iChap, partie: iPart, sequence: iSeq }
      }
      if (!partie.isDone) return { chapitre: iChap, partie: iPart, sequence: 0 }
    }
    if (!chapitre.isDone) return { chapitre: iChap, partie: 0, sequence: 0 }
  }

  return { chapitre: 0, partie: 0, sequence: 0 }
}

function calculateProgress(formation) {
  var data = { total: 0, done: 0 }
  for (var chapitre of formation.chapitre) {
    for (var partie of chapitre.partie) {
      data.total++
      if (partie.isDone) data.done++
    }
  }

  return data.total > 0 ? Math.round((data.done / data.total) * 100) : 0
}
