import React, { Component } from 'react'
import { Slider } from '@material-ui/core'
import Format from './Format.js'
import MaskQuizz from '../helpers/MaskQuizz.js'
import Button from '../Button'
import EditableField from '../helpers/EditableField.js'
import numberBackground from '../../../img/numberBackground.svg'
import QuestionnaireDropdown from '../../../admin/components/dropdown/QuestionnaireDropdown.js'

class FormatQuestionnaireV2 extends Format {
  constructor(props) {
    super(props)

    this.state = {
      userChoice: this.props.format.userChoice?.length > 0 ? parseInt(this.props.format.userChoice[0]) : null,
      isAnswer: false,
      isMobile: window.innerWidth <= 600,
    }
  }

  updateIsMobile() {
    this.setState(() => ({ isMobile: window.innerWidth <= 600 }))
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile.bind(this))
  }

  handleSliderChange = (event, newValue) => {
    this.setState((prevstate) => ({userChoice: newValue - 1}))
  }

  manageResponse = () => {
    if (!this.props.format.isDone) {
      this.props.onDataChange([{ key: 'isDone', value: true }, { key: 'userChoice', value: [this.state.userChoice] }])
    }
    this.props.manageSlide(1)
  }


  render() {

    const marks = []
    for(var i=0; i < this.props.format.responses.length; i++){
      marks.push({value: i+1, label: this.props.format.responses[i].label})
    }

    const label = this.state.userChoice !== null ? this.props.format.responses[this.state.userChoice].label : '?'
    return (
      <div className="format_quizz format_questionnaire formatBox">
        <div className="format_wrapper">
          <div>
            {' '}
            {(this.props.isEditor || this.props.format.exerciceTitle) && (
              <EditableField
                isLanding={this.props.isLanding}
                tag="h1"
                field={this.props.format.exerciceTitle}
                classTag="xs"
                isEditor={this.props.isEditor}
                type="exerciceTitle"
              />
            )}{' '}
          </div>
          <div>
            <div className="main_wrapper">
              <EditableField
                isLanding={this.props.isLanding}
                tag="h5"
                field={this.props.format.questions}
                isEditor={this.props.isEditor}
                type="questions"
              />
              {this.state.isMobile === false ? (
                <div className="slider">
                  <Slider
                    disabled={this.state.isAnswer}
                    value={this.state.userChoice + 1}
                    onChange={this.handleSliderChange}
                    aria-labelledby="discrete-slider-restrict"
                    step={null}
                    valueLabelDisplay="off"
                    marks={marks}
                    max={this.props.format.responses.length}
                    min={1}
                  />
                </div>
              ) : (
                <div className="slider" style={{height:this.props.format.responses.length*65+'px'}}>
                  <Slider
                    sx={{
                      '& input[type="range"]': {
                        WebkitAppearance: 'slider-vertical',
                      },
                    }}
                    orientation="vertical"
                    disabled={this.state.isAnswer}
                    value={this.state.userChoice + 1}
                    onChange={this.handleSliderChange}
                    aria-labelledby="discrete-slider-restrict"
                    step={null}
                    valueLabelDisplay="off"
                    marks={marks}
                    max={this.props.format.responses.length}
                    min={1}
                  />
                </div>
              )}
            </div>
            <div className="btn_wrapper">
              <Button
                disabled={this.state.userChoice == null}
                key="txt_btn_next"
                btn={this.props.format.button}
                callback={() => {
                  this.manageResponse()
                }}
                isEditor={this.props.isEditor}
                isLanding={this.props.isLanding}
              />
            </div>
          </div>
          <div></div>
        </div>
        <MaskQuizz image={this.props.format.image} className="maskQuizz">
          <p className="questionnaire_icon" style={{fontSize:`${234/Math.max(1,1.4*Math.log(label.length +1))}px`}}>{label}</p>
          {this.state.isMobile && <img src={numberBackground} className="number-background" />}
        </MaskQuizz>
        {this.props.isEditor && <QuestionnaireDropdown core={this}/>}
      </div>
    )
  }
}

export default FormatQuestionnaireV2
