import add from '../../../img/add.svg'

import EditableField from '../../../components/player/helpers/EditableField.js'
import Dropdown from '../../../components/player/helpers/Dropdown.js'
import Toggle from '../../../components/player/helpers/Toggle.js'

import React, { useEffect, Component } from 'react'

import updateFormation from '../../../utils/updateFormation'

class DiagnosticDropdown  extends Component {
  constructor(props){
    super(props)
    this.core = props.core
  }

  addStep = () => {
    var data = this.core.props.format.total
    data.push({ seuil: 0, title: null, text: 'Vous êtes sur le bon chemin...'})
    updateFormation('total', data, this.core.props.isLanding)
  }

  removeStep = (i) => {
    var data = this.core.props.format.total
    if(this.core.state.stepIndex >= data.length - 1) this.switchStep(data[this.core.state.stepIndex - 1].seuil)
    data.splice(i, 1)
    updateFormation('total', data, this.core.props.isLanding)
  }

  updateSeuilOrder = () =>{
    var data = this.core.props.format.total
    data.sort((a,b)=>(b.seuil - a.seuil))
    updateFormation('total', data, this.core.props.isLanding)
  }

  switchStep = (seuil) => {
    var userResult = {...this.core.state.userResult}
    userResult.master.score = seuil
    this.core.setState((prevstate) => ({ userResult: userResult, stepIndex: this.core.getStepIndex(seuil, this.core.props.format.total) }))
  }

  reset = () => {
    var userResult = this.core.getUserResult()
    this.core.setState({ userResult: userResult, stepIndex: this.core.getStepIndex(userResult.master.score, this.core.props.format.total) })
  }

  handleCheckbox = (e) => {
    var cats = [...this.core.props.format.categorie];
    const key = e.target.name
    if(cats.filter(c => c.key == key).length > 0){
      for( var i = 0; i < cats.length; i++)if ( cats[i].key == key) cats.splice(i, 1);
    }else{
      cats.push( {key: key, title: null, position: cats.length, seuils: [{ seuil: 0, text: null}]})
    }
    for( var i = 0; i < cats.length; i++) cats[i].position = i
    updateFormation('categorie', cats, this.core.props.isLanding)
  }

  addCategoryStep = (iCat) => {
    var data = this.core.props.format.categorie
    data[iCat].seuils.push({ seuil: 0, text: null})
    updateFormation('categorie', data, this.core.props.isLanding)
  }

  removeCategoryStep = (iCat, iStep) => {
    var data = this.core.props.format.categorie
    const cat = data[iCat]
    if(this.core.state.categoriesIndex[cat.key] >= cat.seuils.length - 1) this.switchCategoryStep(data[this.core.state.categoriesIndex[cat.key] - 1].seuil, iCat, cat.key)
    data[iCat].seuils.splice(iStep, 1)
    updateFormation('categorie', data, this.core.props.isLanding)
  }

  switchCategoryStep = (seuil, iCat, key) => {
    var userResult = {...this.core.state.userResult}
    userResult[key].score = seuil
    var categoriesIndex = {...this.core.state.categoriesIndex}
    categoriesIndex[key] = this.core.getStepIndex(seuil, this.core.props.format.categorie[iCat].seuils)
    this.core.setState((prevstate) => ({ userResult: userResult, categoriesIndex: categoriesIndex}))
  }

  moveCat = (iCat, nMove) => {
    var data = this.core.props.format.categorie
    const tmp = data.splice(iCat, 1)
    data.splice(iCat + nMove, 0, tmp[0])
    updateFormation('categorie', data, this.core.props.isLanding)
  }

  updateCatOrder = (iCat) =>{
     var data = this.core.props.format.categorie
     data[iCat].seuils.sort((a,b)=>(b.seuil - a.seuil))
     updateFormation('categorie', data, this.core.props.isLanding)
  }



  render() {

    var data = this.core.props.format.categorie
    let saveForAutoDelete = false;
    data.forEach((cat) => {
      if (!this.core.state.userResult[cat.key]) {
        let indexToDelete = data.findIndex((el) => el.key === cat.key)
        data.splice(indexToDelete, 1)
        saveForAutoDelete = true
      }
    })

    if (saveForAutoDelete ===  true) {
      updateFormation('categorie', data, this.core.props.isLanding)
    }


    return (
      <Dropdown side='left'>
        <div className="dropdown_item">
          <div style={{width:'100%'}}>
            <h4 style={{marginBottom:'5px', fontSize:'10px'}}>Seuils `master` (gauche)</h4>
            <div className='steps_diagn'>
              {this.core.props.format.total.map((step, i)=>(
                <div  className='step_diagn' key={i}>
                  <div className='step_input'>
                    <svg className={i == this.core.state.stepIndex ? 'activated' : null} onClick={(e) => this.switchStep(step.seuil)} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m348.41 504.58c186.98 0 342.3-182.57 342.3-182.57 12.148-13.004 12.488-34.48 0.38281-47.59 0 0-147.64-182.07-341.07-182.07-193.43 0-341.07 182.07-341.07 182.07-11.938 13.281-12 34.836 0.21094 47.789 0 0 152.27 182.37 339.25 182.37zm1.6133-60.621c80.121 0 145.07-65.137 145.07-145.49 0-80.352-64.953-145.49-145.07-145.49-80.125 0-145.07 65.141-145.07 145.49 0 80.352 64.949 145.49 145.07 145.49zm0-80.828c35.609 0 64.477-28.949 64.477-64.66 0-35.711-28.867-64.664-64.477-64.664-35.609 0-64.48 28.953-64.48 64.664 0 35.711 28.871 64.66 64.48 64.66z" fillRule="evenodd"/></svg>
                    <span style={{fontSize:"12px", margin:'0 5px 0 10px'}}> ≥ : </span>
                    <EditableField
                      isLanding={this.core.props.isLanding}
                      tag="span"
                      onBlur={this.updateSeuilOrder}
                      field={step.seuil}
                      isEditor={this.core.props.isEditor}
                      type="total"
                      onInputChange={(number) => this.core.handleStepsChange(number? parseInt(number): 0, 'seuil',i)}
                    />
                    <span>%</span>
                  </div>
                  {this.core.props.format.total.length > 2 &&
                    <svg  className={'diagn_delete'} onClick={()=> this.removeStep(i)} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m491.51 177.63h-283.02c-4.8047-0.03125-9.3945 1.9961-12.605 5.5742-3.2148 3.5742-4.7383 8.3555-4.1953 13.129l28 244.5c2.0234 17.77 10.516 34.176 23.859 46.086 13.348 11.906 30.609 18.488 48.492 18.48h120.57c18.113 0 35.574-6.7539 48.973-18.945 13.402-12.188 21.777-28.934 23.488-46.965l23.188-243.49c0.44141-4.7188-1.1328-9.4062-4.332-12.906s-7.7305-5.4844-12.469-5.4609z"/><path d="m424.48 93.801c0-10.398-4.1289-20.367-11.48-27.719-7.3516-7.3516-17.324-11.48-27.719-11.48h-70.562c-10.395 0-20.367 4.1289-27.719 11.48-7.3516 7.3516-11.48 17.32-11.48 27.719v14.785h-124.77v33.602l398.49-0.003906v-33.602l-124.77 0.003907zm-33.602 14.785h-81.758v-14.785c0-3.0938 2.5078-5.6016 5.5977-5.6016h70.562c1.4844 0 2.9102 0.58984 3.957 1.6406 1.0508 1.0508 1.6406 2.4766 1.6406 3.9609z"/></svg>
                  }
                </div>
              ))}
              <div  className='step_diagn' onClick={this.addStep}>
                <div style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                  <img className='add_diag_seuil' src={add} />
                  <span style={{fontSize:"12px", marginLeft:'5px'}}>Ajoutez un seuil</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dropdown_item">
          <div style={{width:'100%'}}>
            <h4 style={{marginBottom:'5px', fontSize:'10px'}}>Categories (droite)</h4>
            <div className='steps_diagn_cat' style={{margin:'5px 0'}}>
              {Object.keys(this.core.state.userResult).map((key, iCat)=> (
                  <label className=' step_input'  key={iCat + '_' +key}>
                    <input
                      style={{display:'block'}}
                      name={key}
                      type="checkbox"
                      checked={this.core.props.format.categorie.filter(c => c.key === key).length > 0}
                      onChange={this.handleCheckbox}
                    />
                    {key}
                  </label>
                )
              )}
            </div>
            {this.core.props.format.categorie.map((cat,iCat) =>
              <div className='steps_diagn' key={iCat + '_stepsDiag'} style={{marginBottom:'8px'}}>
                <div className='step_diagn'>
                  <span style={{fontSize:"12px", marginLeft:'5px'}}>{`${cat.key}: `}<i style={{fontSize:"10px"}}>{`${this.core.state.userResult[cat.key] ? this.core.state.userResult[cat.key].max : 0}pts max`}</i></span>
                  <div className='step_input'>
                    {iCat !== 0 && <div onClick={()=> this.moveCat(iCat,-1)} className="tableNav">▲</div>}
                    {this.core.props.format.categorie.length > 1 && iCat !== this.core.props.format.categorie.length - 1 && <div onClick={()=> this.moveCat(iCat,+1)} className="tableNav">▼</div>}
                  </div>
                </div>
                {cat.seuils.map((step, iStep)=>(
                  <div  className='step_diagn' key={iStep}>
                    <div className='step_input'>
                      <svg className={iStep == this.core.state.categoriesIndex[cat.key] ? 'activated' : null} onClick={(e) => this.switchCategoryStep(step.seuil, iCat, cat.key )} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m348.41 504.58c186.98 0 342.3-182.57 342.3-182.57 12.148-13.004 12.488-34.48 0.38281-47.59 0 0-147.64-182.07-341.07-182.07-193.43 0-341.07 182.07-341.07 182.07-11.938 13.281-12 34.836 0.21094 47.789 0 0 152.27 182.37 339.25 182.37zm1.6133-60.621c80.121 0 145.07-65.137 145.07-145.49 0-80.352-64.953-145.49-145.07-145.49-80.125 0-145.07 65.141-145.07 145.49 0 80.352 64.949 145.49 145.07 145.49zm0-80.828c35.609 0 64.477-28.949 64.477-64.66 0-35.711-28.867-64.664-64.477-64.664-35.609 0-64.48 28.953-64.48 64.664 0 35.711 28.871 64.66 64.48 64.66z" fillRule="evenodd"/></svg>
                      <span style={{fontSize:"12px", margin:'0 5px 0 10px'}}> ≥ : </span>
                      <EditableField
                        onBlur={()=>this.updateCatOrder(iCat)}
                        isLanding={this.core.props.isLanding}
                        tag="span"
                        field={step.seuil}
                        type="categorie"
                        isEditor={this.core.props.isEditor}
                        key={`categoriesSeuil_${iCat}_${iStep}`}
                        onInputChange={(number) => this.core.handleCategorySeuilChange(iCat, iStep, number? parseInt(number): 0, 'seuil')}
                      />
                      <span>%</span>
                    </div>
                    {cat.seuils.length > 1 &&
                      <svg  className={'diagn_delete'} onClick={()=> this.removeCategoryStep(iCat, iStep)} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m491.51 177.63h-283.02c-4.8047-0.03125-9.3945 1.9961-12.605 5.5742-3.2148 3.5742-4.7383 8.3555-4.1953 13.129l28 244.5c2.0234 17.77 10.516 34.176 23.859 46.086 13.348 11.906 30.609 18.488 48.492 18.48h120.57c18.113 0 35.574-6.7539 48.973-18.945 13.402-12.188 21.777-28.934 23.488-46.965l23.188-243.49c0.44141-4.7188-1.1328-9.4062-4.332-12.906s-7.7305-5.4844-12.469-5.4609z"/><path d="m424.48 93.801c0-10.398-4.1289-20.367-11.48-27.719-7.3516-7.3516-17.324-11.48-27.719-11.48h-70.562c-10.395 0-20.367 4.1289-27.719 11.48-7.3516 7.3516-11.48 17.32-11.48 27.719v14.785h-124.77v33.602l398.49-0.003906v-33.602l-124.77 0.003907zm-33.602 14.785h-81.758v-14.785c0-3.0938 2.5078-5.6016 5.5977-5.6016h70.562c1.4844 0 2.9102 0.58984 3.957 1.6406 1.0508 1.0508 1.6406 2.4766 1.6406 3.9609z"/></svg>
                    }
                  </div>
                ))}
                <div  className='step_diagn' onClick={() => this.addCategoryStep(iCat)}>
                  <div style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                    <img className='add_diag_seuil' src={add} />
                    <span style={{fontSize:"12px", marginLeft:'5px'}}>Ajoutez un seuil</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="dropdown_item">
          <Toggle
            isLanding={this.core.props.isLanding}
            type="isChapter"
            check={this.core.props.format.isChapter}
            label="Feedback de chapitre"
            callback={this.reset}
          />
        </div>
      </Dropdown>
    )
  }
}

export default DiagnosticDropdown
