import Format from './Format.js'
import { connect } from 'react-redux'

import Question from '../helpers/Question.js'
import MaskQuizz from '../helpers/MaskQuizz.js'
import MaskText from '../helpers/MaskText.js'
import Button from '../Button'
import React, { Component } from 'react'

import EditableField from '../helpers/EditableField.js'
import EditableArea from '../helpers/EditableArea.js'

import { onFormationChange } from '../../../admin/actions/playerActions.js'
import QuizzDropdown from '../../../admin/components/dropdown/QuizzDropdown.js'

class FormatQuizz extends Format {
  constructor(props) {
    super(props)

    var isAnswer = false
    //if (this.props.format.userIsValid !== null || this.props.format.userIsValid !== undefined ) { isAnswer = true }
    if (this.props.format.userIsValid === true) {isAnswer = true}

    this.state = {
      isAnswer: isAnswer,
      responses: props.format.responses,
      userChoice: isAnswer ? this.props.format.userChoice : null,
      userIsValid: isAnswer ? this.props.format.userIsValid : null,
      showButtonForAdmin: props.isEditor ? true : false,
      answerEditor: props.format.answers,
    }
  }

  componentDidUpdate() {
    if (this.props.format.responses !== this.state.responses) {
      this.setState({ responses: this.props.format.responses })
    }
  }

  onAnswer = (userChoice, userIsValid) => {
    this.props.onDataChange([{ key: 'userChoice', value: userChoice }, { key: 'userIsValid', value: userIsValid }])
    this.setState({ isAnswer: true, userChoice: userChoice, userIsValid: userIsValid })

    if (this.props.format.noValidAnswer) {
      this.manageResponse();
    }
  }

  answerText = () => {
    var format = this.props.format
    if (this.state.userIsValid === true) {
      if (format.noValidAnswer === true) {
        return format.answers.title
      } else if (format.noValidAnswer === false) {
        return format.answers.good
      }
    } else if (this.state.userIsValid === false) {
      return format.answers.bad
    }

    return 'Découvrons la bonne réponse !'
  }

  updateReponse = (data) => {
    this.setState({ responses: data })
  }

  onNoValidAnswerChange = (checked) => {
    var data = [...this.state.responses]
    for (var i = 0; i < data.length; i++) {
      data[i].isValid = checked ? null : false
    }
    if (!checked) data[0].isValid = true

    var answer = this.state.answerEditor
    if (checked) {
      delete answer.good
      delete answer.bad
      answer.title = 'Découvrons pourquoi!'
    } else {
      delete answer.title
      answer.good = 'Bonne réponse !'
      answer.bad = 'Mauvaise réponse'
    }
    this.setState({
      responses: data,
      isAnswer: false,
      showButtonForAdmin: true,
      answerEditor: answer,
    })
    this.props.onFormationChange(
      'responses',
      data,
      this.props.index,
      this.props.isLanding ? this.props.landing : this.props.formation,
      this.props.isLanding ? true : false
    )
    this.props.onFormationChange(
      'answers',
      answer,
      this.props.index,
      this.props.isLanding ? this.props.landing : this.props.formation,
      this.props.isLanding ? true : false
    )
  }

  onEditorChange = (text, type) => {
    var data = this.state.answerEditor
    data[type] = text
    this.setState({ answers: data })

    return { data: data, index: false }
  }

  renderQuestion = () => {
    var image = this.props.format.image

    return (
      <>
        <Question
          buttonNext={this.props.format.buttonNext}
          isAnswer={this.state.isAnswer}
          userChoice={this.state.userChoice}
          isMulti={this.props.format.multi}
          noValidAnswer={this.props.format.noValidAnswer}
          isEditor={this.props.isEditor}
          keyName={this.props.keyName}
          isLanding={this.props.isLanding}
          responses={this.state.responses}
          onAnswer={this.onAnswer}
          updateReponse={this.updateReponse}
          showButtonForAdmin={this.state.showButtonForAdmin}
        />
        {this.state.isAnswer && (
          <>
            {this.state.userIsValid === true && (
              <>
              {this.props.format.noValidAnswer === true ? (
                <>
                  {/*<EditableField
                    isLanding={this.props.isLanding}
                    tag="h4"
                    field={this.state.answerEditor.title}
                    isEditor={this.props.isEditor}
                    type="answers"
                    onInputChange={(e) => {
                      return this.onEditorChange(e, 'title')
                    }}
                  />*/}
                </>
              ) : (
                <EditableField
                  isLanding={this.props.isLanding}
                  tag="h4"
                  field={this.state.answerEditor.good}
                  isEditor={this.props.isEditor}
                  type="answers"
                  onInputChange={(e) => {
                    return this.onEditorChange(e, 'good')
                  }}
                />
              )}
              </>

            )}
            {this.state.userIsValid === false && (
              <EditableField
                isLanding={this.props.isLanding}
                tag="h4"
                field={this.state.answerEditor.bad}
                isEditor={this.props.isEditor}
                type="answers"
                onInputChange={(e) => {
                  return this.onEditorChange(e, 'bad')
                }}
              />
            )}
            {this.props.format.noValidAnswer === true &&
              <div style={{ padding: '5px ' + (image ? '0' : '45') + 'px 0' }}>
                <EditableArea
                  isLanding={this.props.isLanding}
                  tag="div"
                  isEditor={this.props.isEditor}
                  field={this.state.answerEditor.text}
                  type="answers"
                  onInputChange={(e) => {
                    return this.onEditorChange(e, 'text')
                  }}
                />
              </div>
            }
            <div className="btn_wrapper">
              <Button
                key="txt_btn_next"
                isEditor={this.props.isEditor}
                isLanding={this.props.isLanding}
                btn={this.props.format.button}
                callback={() => {
                  this.manageResponse()
                }}
              />
            </div>
          </>
        )}
      </>
    )
  }

  render() {
    return (
      <div className="format_quizz formatBox">
        <div className="format_wrapper">
          <div>
            {this.props.isEditor || this.props.format.exerciceTitle ? (
              <EditableField
                isLanding={this.props.isLanding}
                tag="h1"
                classTag="xs"
                field={this.props.format.exerciceTitle}
                isEditor={this.props.isEditor}
                type="exerciceTitle"
              />
            ) : (
              <>{''}</>
            )}
          </div>
          <div>
            <EditableField
              isLanding={this.props.isLanding}
              tag="h5"
              field={this.props.format.questions}
              isEditor={this.props.isEditor}
              type="questions"
            />
            {!!this.props.format.multi && (
              <p>
                <i>Plusieurs réponses sont possibles</i>
              </p>
            )}
            {this.props.format.image && this.renderQuestion()}
          </div>
          <div></div>
        </div>
        {this.props.format.image ? (
          <MaskText image={this.props.format.image} />
        ) : (
          <MaskQuizz className="maskQuizz">{this.renderQuestion()}</MaskQuizz>
        )}
        {this.props.isEditor && (
          <>
            <QuizzDropdown core={this}/>
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  formation: state.playerFormation,
  landing: state.playerLanding,
  index: state.playerIndex,
  playerReduxBug: state.playerReduxBug,
})

export default connect(mapStateToProps, { onFormationChange })(FormatQuizz)
