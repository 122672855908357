import React, { Component } from 'react'

class EmptyHeader extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <header className="header__home">
        <div className="home__nav">
          <div style={{ display: 'flex' }}>
            <a href="https://risinguparis.com">
              <img
                src="https://video.thelab.risinguparis.com/img/logowhite.png"
                alt="logo Rising Up blanc"
                className="header__logo"
              />
            </a>
          </div>
        </div>
      </header>
    )
  }
}

export default EmptyHeader
