import React, { Component } from 'react'
import { Slider } from '@material-ui/core'
import Format from './Format.js'
import MaskQuizz from '../helpers/MaskQuizz.js'
import Button from '../Button'
import EditableField from '../helpers/EditableField.js'
import Dropdown from '../helpers/Dropdown.js'
import numberBackground from '../../../img/numberBackground.svg'

const marks = [
  { value: 0, label: '1' },
  { value: 33, label: '2' },
  { value: 66, label: '3' },
  { value: 100, label: '4' },
]

class FormatQuestionnaire extends Format {
  constructor(props) {
    super(props)
    this.state = {
      value: 50,
      label: false,
      isAnswer: false,
      isMobile: window.innerWidth <= 600,
    }
    //Si déja répondue par User
    if (this.props.format.userChoice !== null) {
      this.state.isAnswer = true
      this.state.label = this.props.format.userChoice
      this.state.value = convertLabel(parseInt(this.props.format.userChoice))
    }
  }

  updateIsMobile() {
    this.setState(() => ({ isMobile: window.innerWidth <= 600 }))
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile.bind(this))
  }

  handleSliderChange = (event, newValue) => {
    this.setState((prevstate) => ({
      value: newValue,
      label: convertValue(newValue),
    }))
  }

  manageResponse = () => {
    if (!this.props.format.isDone) {
      this.props.onDataChange([{ key: 'isDone', value: true }, { key: 'userChoice', value: this.state.label }])
    }
    this.props.manageSlide(1)
  }

  render() {
    return (
      <div className="format_quizz format_questionnaire formatBox">
        <div className="format_wrapper">
          <div>
            {' '}
            {(this.props.isEditor || this.props.format.exerciceTitle) && (
              <EditableField
                isLanding={this.props.isLanding}
                tag="h1"
                field={this.props.format.exerciceTitle}
                classTag="xs"
                isEditor={this.props.isEditor}
                type="exerciceTitle"
              />
            )}{' '}
          </div>
          <div>
            <div className="main_wrapper">
              <EditableField
                isLanding={this.props.isLanding}
                tag="h5"
                field={this.props.format.questions}
                isEditor={this.props.isEditor}
                type="questions"
              />
              {/* <h5>{this.props.format.questions}</h5> */}
              <div className="slider">
                <Slider
                  disabled={this.state.isAnswer}
                  value={this.state.value}
                  onChange={this.handleSliderChange}
                  aria-labelledby="discrete-slider-restrict"
                  step={null}
                  valueLabelDisplay="off"
                  marks={marks}
                />
              </div>
            </div>
            <div className="btn_wrapper">
              <Button
                disabled={!this.state.label}
                key="txt_btn_next"
                btn={this.props.format.button}
                callback={() => {
                  this.manageResponse()
                }}
                isEditor={this.props.isEditor}
                isLanding={this.props.isLanding}
              />
            </div>
          </div>
          <div></div>
        </div>
        <MaskQuizz image={this.props.format.image} className="maskQuizz">
          <p className="questionnaire_icon">{this.state.label ? this.state.label : '?'}</p>
          {this.state.isMobile && <img src={numberBackground} className="number-background" />}
        </MaskQuizz>
        {this.props.isEditor && (
          <Dropdown>
            <div className="dropdown_item">
              <p>Aucunes options</p>
            </div>
          </Dropdown>
        )}
      </div>
    )
  }
}

export default FormatQuestionnaire

function convertLabel(label) {
  var newValue
  if (label === 1) {
    newValue = 0
  } else if (label === 2) {
    newValue = 33
  } else if (label === 3) {
    newValue = 66
  } else if (label === 4) {
    newValue = 100
  }
  return newValue
}

function convertValue(value) {
  var newLabel
  if (value === 0) {
    newLabel = 1
  } else if (value === 33) {
    newLabel = 2
  } else if (value === 66) {
    newLabel = 3
  } else if (value === 100) {
    newLabel = 4
  }
  return newLabel
}
