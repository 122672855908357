import React, { Component } from 'react'
import EditableField from './EditableField.js'

class Donut extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    var stroke = 248

    if (this.props.i !== null) stroke = 248 * (this.props.i / this.props.total)
    return (
      <div className="feedback_donut">
        <div className="nombreQues">
          <svg className="svg-donut CImobile" width="79px" height="79px">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <circle
                className={this.props.name}
                strokeDasharray={stroke + ',248'}
                fill="#ffffff21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                transform="translate(39.5, 39.5) scale(1, -1) translate(-39.5, -39.5) "
                cx="39.5"
                cy="39.5"
                r="38.5"
              ></circle>
            </g>
          </svg>
          <style>
            {'@keyframes ' + this.props.name + '{'}
            {'	0% {stroke-dasharray: 0, 248;}'}
            {'	100% {stroke-dasharray: ' + stroke + ',248;}'}
            {'}'}
          </style>
          {this.props.innerText ? (
            <span className="quizzGodd quizzGodd_xs">{this.props.innerText}</span>
          ) : this.props.i !== null ? (
            <span className="quizzGodd quizzGodd_xs">
              {this.props.i}/{this.props.total}
            </span>
          ) : (
            <span className="quizzGodd">{this.props.total}</span>
          )}
        </div>
        <EditableField
          isLanding={this.props.isLanding}
          tag="p"
          field={this.props.title}
          isEditor={this.props.isEditor}
          type={this.props.editableType}
        />
      </div>
    )
  }
}

export default Donut
