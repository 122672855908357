import React, { useEffect, useContext, useState, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { initData } from '../../actions/formationActions'
import axios from 'axios'

import { registerUser, loginUser } from '../../actions/authActions'

import { GLB_URL_APIS } from '../../actions/types'

import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

import LanguageContext from "../../contexts/LanguageContext"

import { Box, FormControl, Select, MenuItem, InputLabel, Checkbox } from '@material-ui/core'
import styled from "styled-components";

import Layout, { StyledTextField, StyledButton } from "../mainLayout/Layout"
import AlertDialog from "../mainLayout/AlertDialog"


import frenchLanguage from '../../img/frenchLanguage.png';
import englishLanguage from '../../img/englishLanguage.png';
export const languages = [
  {
    label: "Français",
    src: frenchLanguage,
    value: "fr"
  },
  {
    label: "English",
    src: englishLanguage,
    value: "en"
  },
];

const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    color : #BEBEBE;
  }
  &.MuiCheckbox-root:hover {
    background : transparent;
  }
  &.MuiCheckbox-root.Mui-checked {
    color : #0C457C;
  }
`;

const Register = (props) => {
  const languageContext = useContext(LanguageContext);

  useEffect(() => {
    if (props.auth.isAuthenticated === true) {
      props.history.push("/dashboard");
    }
  }, [props.auth])

  const [step, setStep] = useState(0)
  const languageRef = useRef(null)

  const [code, setCode] = useState("")
  const [codeError, setCodeError] = useState(false)
  const [submittingCode, setSubmittingCode] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [checkRU, setCheckRU] = useState(false)

  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [submittingRegister, setSubmittingRegister] = useState(false)
  const [registerError, setRegisterError] = useState({})

  const handleRegister = () => {
    setSubmittingRegister(true)

    const newUser = {
      code: code,
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
      password2: password,
      language : languageContext.language,
    }

    axios
      .post(GLB_URL_APIS+ '/user/register', newUser)
      .then((res) => {
        if (res.data.isOk === true) {
          const userData = {
            email: email,
            password: password,
            language : languageContext.language,
          }

          props.loginUser(userData) // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter*/
        } else {
          setRegisterError(res.data.errors)
        }
        setSubmittingRegister(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Layout componentsDown={
      <Box sx={{width:"100%", display:'flex', flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <Box onClick={() => { props.history.push("/login") }} sx={{cursor:"pointer", fontSize:"14px", display:"flex", gap:"5px"}}>
          <Box sx={{color:"#626262"}}>
            {languageContext.values.register.alreadyAccount[languageContext.language]}
            &nbsp;<u style={{color:"black"}}>{languageContext.values.global.click[languageContext.language]}</u>
          </Box>
        </Box>
        <Box sx={{width:"100%", maxWidth : "600px", marginTop:"20px", marginBottom:"15px", display:'flex', gap:["20px", "35px", "35px", "35px"]}}>
          <Box sx={{flex:1, height:'6px', borderRadius:"3px", background: "#0C457C"}}>
          </Box>
          <Box sx={{flex:1, height:'6px', borderRadius:"3px", background: step >= 1 ? "#0C457C" : "#C7D3DE"}}>
          </Box>
          <Box sx={{flex:1, height:'6px', borderRadius:"3px", background: step >= 2 ? "#0C457C" : "#C7D3DE"}}>
          </Box>
          <Box sx={{flex:1, height:'6px', borderRadius:"3px", background: "#C7D3DE"}}>
          </Box>
        </Box>
      </Box>
    }>
      {step === 2 &&
        <>
          <Box sx={{marginBottom:"30px", fontSize: "25px", fontWeight:"bold",}}>
            {languageContext.values.register.infos.title[languageContext.language]}
          </Box>
          {/*<Box sx={{marginBottom:"30px", fontSize: "14px", color:"#626262",}}>
            {languageContext.values.register.infos.subTitle[languageContext.language]}
          </Box>*/}
          <Box sx={{display:"flex", gap:"15px",}}>
            <StyledTextField
              fullWidth
              error={registerError.firstname !== undefined}
              label={languageContext.values.register.firstNameField[languageContext.language]}
              variant="outlined"
              value={firstName}
              onChange={(e) => { setFirstName(e.target.value); setRegisterError({}); }}
            />
            <StyledTextField
              fullWidth
              error={registerError.lastname !== undefined}
              label={languageContext.values.register.lastNameField[languageContext.language]}
              variant="outlined"
              value={lastName}
              onChange={(e) => { setLastName(e.target.value); setRegisterError({});}}
            />
          </Box>
          <StyledTextField
            fullWidth
            error={registerError.email !== undefined}
            label={languageContext.values.register.emailField[languageContext.language]}
            variant="outlined"
            value={email}
            onChange={(e) => { setEmail(e.target.value); setRegisterError({}); }}
          />
          <StyledTextField
            fullWidth
            error={registerError.password !== undefined}
            type="password"
            label={languageContext.values.register.passwordField[languageContext.language]}
            variant="outlined"
            value={password}
            onChange={(e) => { setPassword(e.target.value); setRegisterError({}); }}
            sx={{marginBottom:'5px !important'}}
          />
          <Box sx={{display:'flex', width:'100%', alignItems:'center',}}>
            <StyledCheckbox
              checked={checkRU}
              onClick={(e) => { setCheckRU(!checkRU) }}
            />
            <span style={{color:"#626262", fontSize:"11px"}}>
              {languageContext.values.register.infos.ruTexte[languageContext.language]}
              <span
                onClick={() => {
                  if (languageContext.language === "fr") {
                    window.open(window.location.origin+"/terms_FR", "_blank")
                  } else {
                    window.open(window.location.origin+"/terms_EN", "_blank")
                  }
                }}
                style={{cursor:"pointer"}}
              >
                <u>{languageContext.values.register.infos.ruTitle[languageContext.language]}</u>
              </span>
            </span>
          </Box>
          <StyledButton
            disabled={submittingRegister === true || checkRU === false || firstName === "" || lastName === "" || email === "" || password.length < 5 || password.length >= 30}
            fullWidth
            variant="contained"
            sx={{marginTop:"10px"}}
            onClick={() => {
              setDialogConfirm(true)
            }}
          >
            {languageContext.values.register.formValid[languageContext.language]}
          </StyledButton>
        </>
      }
      {step === 1 &&
        <>
          <Box sx={{marginBottom:"30px", fontSize: "25px", fontWeight:"bold",}}>
            {languageContext.values.register.code.title[languageContext.language]}
          </Box>
          <StyledTextField
            fullWidth
            error={codeError}
            label={languageContext.values.register.code.field[languageContext.language]}
            variant="outlined"
            value={code}
            onChange={(e) => { setCode(e.target.value); setCodeError(false); }}
          />
          <StyledButton
            disabled={code === "" || submittingCode === true}
            fullWidth
            variant="contained"
            sx={{marginTop:"10px"}}
            onClick={() => {
              setSubmittingCode(true)
              axios
                .post(GLB_URL_APIS+ '/groupe/checkCode', { data: code })
                .then((res) => {
                  if (res.data.isOk === true) {
                    setStep(2)
                  } else {
                    setCodeError(true)
                  }
                  setSubmittingCode(false)
                })
                .catch((err) => console.log(err))
            }}
          >
            {languageContext.values.register.formValid[languageContext.language]}
          </StyledButton>
        </>
      }
      {step === 0 &&
        <>
          <Box sx={{marginBottom:"30px", fontSize: "25px", fontWeight:"bold",}}>
            {languageContext.values.register.language.title[languageContext.language]}
          </Box>
          {/*<Box sx={{marginBottom:"30px", fontSize: "14px", color:"#626262",}}>
            {languageContext.values.register.language.subTitle[languageContext.language]}
          </Box>*/}
          <FormControl fullWidth>
            <Select
              ref={languageRef}
              value={languageContext.language}
              name="language"
              onChange={(e) => { languageContext.changeLanguage(e.target.value) }}
              onClose={() => {
                languageRef.current.classList.remove('Mui-focused');
                languageRef.current.previousSibling?.classList.remove('Mui-focused');
               }}
              onOpen={() => {
                languageRef.current.classList?.add('Mui-focused');
                languageRef.current.previousSibling?.classList.add('Mui-focused');
              }}
              sx={{
                color: 'black',
                background:"#EDEDED",
                marginBottom:"15px",
                '& .MuiSelect-select': {
                  padding:"10px",
                  display:'flex',
                  alignItems:"center",
                  gap:"15px",
                  border:""
                },
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#BEBEBE',
                    borderRadius: '6px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#BEBEBE',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#0C457C',
                  },
                },
              }}
              MenuProps={{
                sx: {
                '& .MuiMenu-paper': {
                  //marginTop:"5px",
                  backgroundColor: '#EDEDED',
                  borderColor: '#BEBEBE',
                  borderRadius: '6px',
                },
                '& .MuiMenuItem-root': {
                    gap:"15px",
                },
                '& .MuiMenuItem-root:hover': {
                    backgroundColor: '#EDEDED',
                    color : "#0C457C",
                },
                '& .Mui-selected': {
                    backgroundColor: '#EDEDED',
                }
                }
              }}
            >
              {languages.map((option, key) => (
                <MenuItem value={option.value} key={key}>
                  <img style={{width:"35px", height:"35px"}} src={option.src} alt={option.label} />{option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <StyledButton
            fullWidth
            variant="contained"
            sx={{marginTop:"10px"}}
            onClick={() => { setStep(1) }}
          >
            {languageContext.values.register.formValid[languageContext.language]}
          </StyledButton>
        </>
      }

      <AlertDialog
        open={dialogConfirm}
        title={languageContext.values.register.infos.verif.title[languageContext.language]}
        subTitle={languageContext.values.register.infos.verif.subTitle[languageContext.language]}
        closeText={languageContext.values.register.infos.verif.closeText[languageContext.language]}
        validText={languageContext.values.register.infos.verif.validText[languageContext.language]}
        handleClose={() => { setDialogConfirm(false) }}
        handleValid={() => { setDialogConfirm(false); handleRegister(); }}
      />
    </Layout>
  )
}



Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, { registerUser, loginUser, initData })(withRouter(Register))
