import React, { Component, lazy } from 'react'
import { BrowserRouter as Switch, Redirect } from 'react-router-dom'

import PrivateRouteAdmin from './PrivateRouteAdmin'

const Groups = lazy(() => import('../admin/components/group/Groups'))
const GroupNew = lazy(() => import('../admin/components/group/GroupNew'))
const Group = lazy(() => import('../admin/components/group/Group'))

const LandingPages = lazy(() => import('../admin/components/landingPage/LandingPages'))
const LandingPage = lazy(() => import('../admin/components/landingPage/LandingPage'))
const LandingPagePlayer = lazy(() => import('../admin/components/landingPage/LandingPagePlayer'))
const LandingNew = lazy(() => import('../admin/components/landingPage/LandingNew'))

const Colors = lazy(() => import('../admin/components/color/Colors'))
const Color = lazy(() => import('../admin/components/color/Color'))
const ColorNew = lazy(() => import('../admin/components/color/ColorNew'))

const Exercices = lazy(() => import('../admin/components/exercice/Exercices'))
const Exercice = lazy(() => import('../admin/components/exercice/Exercice'))

const Formations = lazy(() => import('../admin/components/formation/Formations'))
const Formation = lazy(() => import('../admin/components/formation/Formation'))
const FormationEdit = lazy(() => import('../admin/components/formation/FormationEdit'))
const FormationNew = lazy(() => import('../admin/components/formation/FormationNew'))

const AdminClients = lazy(() => import('../admin/components/adminclient/AdminClients'))
const AdminClientNew = lazy(() => import('../admin/components/adminclient/AdminClientNew'))
const AdminClientEdit = lazy(() => import('../admin/components/adminclient/AdminClientEdit'))

class AdminRoute extends Component {
  render() {
    return (
      <Switch>
        <PrivateRouteAdmin exact path="/admin">
          <Redirect to="/admin/groupe" />
        </PrivateRouteAdmin>
        <PrivateRouteAdmin exact path="/admin/groupe" component={Groups} />
        <PrivateRouteAdmin exact path="/admin/groupe/add" component={GroupNew} />
        <PrivateRouteAdmin exact path="/admin/groupe/edit/:id" component={Group} />

        <PrivateRouteAdmin exact path="/admin/page" component={LandingPages} />
        <PrivateRouteAdmin exact path="/admin/page/add" component={LandingNew} />
        <PrivateRouteAdmin exact path="/admin/page/edit/:id" component={LandingPage} />
        <PrivateRouteAdmin exact path="/admin/page/player/:id" component={LandingPagePlayer} />

        <PrivateRouteAdmin exact path="/admin/color" component={Colors} />
        <PrivateRouteAdmin exact path="/admin/color/add" component={ColorNew} />
        <PrivateRouteAdmin exact path="/admin/color/edit/:id" component={Color} />

        <PrivateRouteAdmin exact path="/admin/exercice" component={Exercices} />
        <PrivateRouteAdmin exact path="/admin/exercice/player/:id" component={Exercice} />

        <PrivateRouteAdmin exact path="/admin/formation" component={Formations} />
        <PrivateRouteAdmin exact path="/admin/formation/add" component={FormationNew} />
        <PrivateRouteAdmin exact path="/admin/formation/player/:id" component={Formation} />
        <PrivateRouteAdmin exact path="/admin/formation/edit/:id" component={FormationEdit} />

        <PrivateRouteAdmin exact path="/admin/adminclient" component={AdminClients} />
        <PrivateRouteAdmin exact path="/admin/adminclient/add" component={AdminClientNew} />
        <PrivateRouteAdmin exact path="/admin/adminclient/edit/:id" component={AdminClientEdit} />
      </Switch>
    )
  }
}

export default AdminRoute
