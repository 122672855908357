const postReducer = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ADMIN':
      return action.data
    case 'ADD_POST':
      return state.concat([action.data])
    case 'DELETE_POST':
      return state.filter((post) => post.id !== action.id)
    case 'EDIT_POST':
      return action.data
    case 'UPDATE':
      return state.map((post) => {
        if (post.id === action.id) {
          return {
            ...post,
            title: action.data.newTitle,
            message: action.data.newMessage,
            editing: !post.editing,
          }
        } else return post
      })
    default:
      return state
  }
}
export default postReducer
