import React from 'react';
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import store from './utils/store'

import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import { LanguageProvider2 } from "./contexts/LanguageContext"
import { PopupNotifProvider } from "./contexts/PopupNotifContext"
import { AdminGroupesProvider2 } from "./contexts/AdminGroupesContext"

import PopupNotif from './PopupNotif'

const muiTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Circular',
      fontWeight : 200,
    },
  },
});

ReactDOM.render(
  <>
    <ThemeProvider theme={muiTheme}>
      <Provider store={store}>
        <PopupNotifProvider>
          <LanguageProvider2>
            <AdminGroupesProvider2>
              <PopupNotif />
              <App />
            </AdminGroupesProvider2>
          </LanguageProvider2>
        </PopupNotifProvider>
      </Provider>
    </ThemeProvider>
  </>
  ,
  document.getElementById('root')
)
