import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

import Format from './Format.js'
import Button from '../Button'
import MaskText from '../helpers/MaskText.js'

import { setLandingUser } from '../../../actions/landingPageActions'

import EditableField from '../helpers/EditableField.js'
import EditableArea from '../helpers/EditableArea.js'
import Dropdown from '../helpers/Dropdown.js'
import ImageSetting from '../../../admin/components/formation/player/ImageSetting.js'

import LanguageContext from "../../../contexts/LanguageContext"

class FormatInscription extends Format {
  constructor(props) {
    super(props)

    this.state = {
      firstname: '',
      email: '',
      errors: {},
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    var errors = {}
    var hasError = false
    if (
      this.state.firstname === '' &&
      this.state.firstname.length < 3 &&
      this.state.firstname.length > 64
    ) {
      errors.firstname = this.context.values.formatInscription.nameFieldError[this.context.language]
      hasError = true
    }
    if (this.state.email === '') {
      errors.email = this.context.values.formatInscription.emailFieldError[this.context.language]
      hasError = true
    }

    if (hasError) {
      this.setState({ errors: errors })
    } else {
      this.props.setLandingUser(this.props.landingPage.id, this.props.landingPage.slug, this.state.email, this.state.firstname)
      this.manageResponse()
    }
  }

  render() {
    const { errors } = this.state
    return (
      <div className="format_text formatBox">
        <div className="format_wrapper">
          {this.props.isEditor || this.props.format.exerciceTitle ? (
            <EditableField
              isLanding={this.props.isLanding}
              tag="h1"
              classTag="xs"
              field={this.props.format.exerciceTitle}
              isEditor={this.props.isEditor}
              type="exerciceTitle"
            />
          ) : (
            ''
          )}

          <EditableArea
            isLanding={this.props.isLanding}
            tag="div"
            field={this.props.format.text}
            isEditor={this.props.isEditor}
            type="text"
          />
          <form onSubmit={this.onSubmit} key="auth_form" className="scoringAuth">
            <div className="form-fields">
              <div className="account">
                <label htmlFor="firstname">{this.context.values.formatInscription.nameFieldTitle[this.context.language]}</label>
                <input
                  onChange={this.onChange}
                  value={this.state.firstname}
                  error={errors.firstname}
                  id="firstname"
                  name="firstname"
                  placeholder={this.context.values.formatInscription.nameFieldPlaceholder[this.context.language]}
                  type="text"
                  required={true}
                  className={classnames('', { invalid: errors.firstname })}
                />
                <span className="red-text">{errors.firstname}</span>
              </div>
              <div className="account">
                <label htmlFor="email">{this.context.values.formatInscription.emailFieldTitle[this.context.language]}</label>
                <input
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  name="email"
                  placeholder="exemple@email.fr"
                  type="email"
                  required={true}
                  className={classnames('', { invalid: errors.email || errors.emailnotfound })}
                />
                <span className="red-text">{errors.email}</span>
              </div>
            </div>
            <div className="btn_wrapper">
              <Button
                key="txt_btn_next"
                isEditor={this.props.isEditor}
                isLanding={this.props.isLanding}
                btn={this.props.format.button}
                callback={() => {}}
              />
            </div>
          </form>
        </div>
        {this.props.format.image ? <MaskText noMobile={true} image={this.props.format.image} /> : ''}
        {this.props.isEditor && (
          <Dropdown>
            <ImageSetting
              isLanding={this.props.isLanding}
              image={this.props.format.image}
              onReRender={this.onReRender}
            />
          </Dropdown>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  landingPage: state.landingPage,
})

export default connect(mapStateToProps, { setLandingUser })(FormatInscription)
FormatInscription.contextType = LanguageContext
