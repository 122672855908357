class FN {
  isIphone() {
    var iphoneText = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ]

    return (
      iphoneText.includes(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
  }
}

export default new FN()
