import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import { onFormationChange } from '../../../admin/actions/playerActions'
import add from '../../../img/add.svg'
import badCheck from '../../../img/badCheck.svg'
import goodCheck from '../../../img/goodCheck.svg'
import Button from '../Button'
import EditableField from './EditableField'

class Question extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAnswer: props.isEditor ? true : props.isAnswer,
      userChoice: props.userChoice,
      isEmpty: true,
      responses: props.responses,
    }
  }

  handleCheck = (e) => {
    var answer = this.props.isMulti && this.state.userChoice !== null ? this.state.userChoice : []
    if (e.target.checked) {
      answer.push(e.target.value)
    } else if (this.props.isMulti) {
      answer.map((v, i) => {
        if (v === e.target.value) answer.splice(i, 1)
      })
    }
    this.setState((prevstate) => ({ userChoice: answer, isEmpty: answer.length == 0 }))
  }

  checkQuizz = () => {
    if(this.props.isEditor) return null
    if (this.state.isEmpty) return null
    var res = this.state.userChoice
    var isValid = true
    if (!this.props.noValidAnswer) {
      var countValid = 0
      this.state.responses.map((item, i) => {
        if (item.isValid) {
          countValid++
          if (!res.includes(i.toString())) isValid = false
        }
      })
      if (countValid !== res.length) isValid = false
    }
    this.setState((prevstate) => ({ isAnswer: true }))

    return this.props.onAnswer(res, this.props.noValidAnswer ? true : isValid)
  }

  handleResponseChange = (text, i) => {
    var data = this.state.responses
    data[i].reponse = text
    this.updateReponse(data, false)
    return { data: data, index: false }
  }

  updateReponse = (data, refresh = true) => {
    this.setState({ responses: data })
    this.props.updateReponse(data)
    if (refresh) {
      var props = this.props
      var content = props.isLanding ? props.landing : props.formation
      this.props.onFormationChange('responses', data, props.index, content, props.isLanding)
    }
  }

  componentDidUpdate() {
    if (this.props.responses !== this.state.responses) {
      this.setState({ responses: this.props.responses })
    }
  }

  render() {

    if (this.props.isAnswer !== this.state.isAnswer) this.state.isAnswer = this.props.isAnswer

    const keyName = this.props.keyName ?? 'quizz'
    return (
      <>
        <div className="answerWrapper">
          {this.state.responses.map((item, i) => {
            return (
              <div className="answer" id={'answer' + i} key={'response-' + i}>
                <input
                  disabled={this.state.isAnswer}
                  onChange={this.handleCheck}
                  type={this.props.isMulti ? 'checkbox' : 'radio'}
                  id={`${keyName}_question_${i}`}
                  key={i + '-input'}
                  name={'question'}
                  checked={this.props.isEditor === true ? null : this.props.userChoice?.includes(i.toString())}
                  value={i}
                />
                <label htmlFor={`${keyName}_question_${i}`} key={i + '-label'}>
                  <span className="unchecked">
                    <span className={'checked' + (this.state.isAnswer && item.isValid !== null ? ' checkAnswered': '')}>
                      <CSSTransition
                        in={this.state.isAnswer && item.isValid !== null}
                        timeout={0}
                        unmountOnExit
                        appear
                      >
                        <span isvalid={this.state.isAnswer && item.isValid ? 'true' : 'false'}>
                          <img className="goodCheck" src={goodCheck} />
                          <img className="badCheck" src={badCheck} />
                        </span>
                      </CSSTransition>
                    </span>
                  </span>
                  <EditableField
                    isLanding={this.props.isLanding}
                    tag="p"
                    field={item.reponse}
                    onInputChange={(e) => {
                      return this.handleResponseChange(e, i)
                    }}
                    isEditor={this.props.isEditor}
                    type="responses"
                  />
                </label>
              </div>
            )
          })}
        </div>
        {(!this.state.isAnswer || this.props.showButtonForAdmin) && (
          <div className="btn_wrapper">
            {/*<Button
              disabled={this.state.isEmpty}
              key="txt_btn_next"
              btn={{ type: 'primary', text: 'Valider' }}
              callback={() => {
                this.checkQuizz()
              }}
            />*/}
            <Button
              disabled={this.state.isEmpty}
              key="txt_btn_next"
              isEditor={this.props.isEditor}
              btn={this.props.buttonNext || { type: 'primary', text: 'Valider' }}
              btnName={'buttonNext'}
              callback={() => {
                this.checkQuizz()
              }}
            />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  formation: state.playerFormation,
  landing: state.playerLanding,
  index: state.playerIndex,
  playerReduxBug: state.playerReduxBug,
})

export default connect(mapStateToProps, { onFormationChange })(Question)
