import EditableField from '../../../components/player/helpers/EditableField.js'
import Dropdown from '../../../components/player/helpers/Dropdown.js'
import Toggle from '../../../components/player/helpers/Toggle.js'

import ImageSetting from '../../../admin/components/formation/player/ImageSetting.js'
import React, { Component } from 'react'
import add from '../../../img/add.svg'
import { CSSTransition } from 'react-transition-group'
import badCheck from '../../../img/badCheck.svg'
import goodCheck from '../../../img/goodCheck.svg'
import updateFormation from '../../../utils/updateFormation.js'

class QuizzDropdown  extends Component {
  constructor(props){
    super(props)
    this.core = props.core
  }

  handleCheckEditor = (e) => {
    if (this.core.props.format.noValidAnswer) return null
    var data = this.core.props.format.responses
    if (!this.core.props.format.multi) for (var i = 0; i < data.length; i++) data[i].isValid = false
    data[parseInt(e.target.value)].isValid = !data[parseInt(e.target.value)].isValid
    for (var i = 0; i < data.length; i++) data[i].points = data[i].isValid ? 1 : 0
    this.updateReponse(data)
  }
   handleResponseChange = (key, value, i) => {
    var data = this.core.props.format.responses
    data[i][key] = value
    this.core.updateReponse(data)
    return { data: data, index: false }
  }

  updateReponse = (data) => {
    this.core.updateReponse(data)
    updateFormation('responses', data, this.core.props.isLanding)
  }

  removeResponse = (i) => {
    var data = this.core.props.format.responses
    data.splice(i, 1)
    this.updateReponse(data)
  }

  addResponse = (e) => {
    var data = this.core.props.format.responses
    data.push({ points : 0, reponse: 'Texte à modifier ', isValid: this.core.props.format.noValidAnswer ? null : false })
    this.updateReponse(data)
  }

  moveCat = (i, nMove) => {
    var data = this.core.props.format.responses
    const tmp = data.splice(i, 1)
    data.splice(i + nMove, 0, tmp[0])
    this.updateReponse(data)
  }

  render() {
    return (
      <Dropdown side='left'>
        <div className="dropdown_item">
          <div style={{width:'100%'}}>
            <h4 style={{marginBottom:'5px', fontSize:'10px'}}>Etapes</h4>
            <div className='steps_diagn'>
              <div className='step_diagn step_input'>
                <svg className={!this.core.state.isAnswer ? 'activated' : null} onClick={(e) => this.core.setState({ isAnswer: false, showButtonForAdmin: true })} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m348.41 504.58c186.98 0 342.3-182.57 342.3-182.57 12.148-13.004 12.488-34.48 0.38281-47.59 0 0-147.64-182.07-341.07-182.07-193.43 0-341.07 182.07-341.07 182.07-11.938 13.281-12 34.836 0.21094 47.789 0 0 152.27 182.37 339.25 182.37zm1.6133-60.621c80.121 0 145.07-65.137 145.07-145.49 0-80.352-64.953-145.49-145.07-145.49-80.125 0-145.07 65.141-145.07 145.49 0 80.352 64.949 145.49 145.07 145.49zm0-80.828c35.609 0 64.477-28.949 64.477-64.66 0-35.711-28.867-64.664-64.477-64.664-35.609 0-64.48 28.953-64.48 64.664 0 35.711 28.871 64.66 64.48 64.66z" fillRule="evenodd"/></svg>
                <span style={{fontSize:"12px", margin:'0 0 0 10px'}}>Réponse en cours</span>
              </div>
              {this.core.props.format.noValidAnswer ? (
                <div className='step_diagn step_input'>
                  <svg className={this.core.state.isAnswer  ? 'activated' : null} onClick={(e) => this.core.setState({ isAnswer: true, userIsValid: true, showButtonForAdmin: false })} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m348.41 504.58c186.98 0 342.3-182.57 342.3-182.57 12.148-13.004 12.488-34.48 0.38281-47.59 0 0-147.64-182.07-341.07-182.07-193.43 0-341.07 182.07-341.07 182.07-11.938 13.281-12 34.836 0.21094 47.789 0 0 152.27 182.37 339.25 182.37zm1.6133-60.621c80.121 0 145.07-65.137 145.07-145.49 0-80.352-64.953-145.49-145.07-145.49-80.125 0-145.07 65.141-145.07 145.49 0 80.352 64.949 145.49 145.07 145.49zm0-80.828c35.609 0 64.477-28.949 64.477-64.66 0-35.711-28.867-64.664-64.477-64.664-35.609 0-64.48 28.953-64.48 64.664 0 35.711 28.871 64.66 64.48 64.66z" fillRule="evenodd"/></svg>
                  <span style={{fontSize:"12px", margin:'0 0 0 10px'}}>Réponse validée</span>
                </div>
              ): (<>
                <div className='step_diagn step_input'>
                  <svg className={this.core.state.isAnswer && this.core.state.userIsValid  ? 'activated' : null} onClick={(e) => this.core.setState({ isAnswer: true, userIsValid: true, showButtonForAdmin: false })} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m348.41 504.58c186.98 0 342.3-182.57 342.3-182.57 12.148-13.004 12.488-34.48 0.38281-47.59 0 0-147.64-182.07-341.07-182.07-193.43 0-341.07 182.07-341.07 182.07-11.938 13.281-12 34.836 0.21094 47.789 0 0 152.27 182.37 339.25 182.37zm1.6133-60.621c80.121 0 145.07-65.137 145.07-145.49 0-80.352-64.953-145.49-145.07-145.49-80.125 0-145.07 65.141-145.07 145.49 0 80.352 64.949 145.49 145.07 145.49zm0-80.828c35.609 0 64.477-28.949 64.477-64.66 0-35.711-28.867-64.664-64.477-64.664-35.609 0-64.48 28.953-64.48 64.664 0 35.711 28.871 64.66 64.48 64.66z" fillRule="evenodd"/></svg>
                  <span style={{fontSize:"12px", margin:'0 0 0 10px'}}>Bonne réponse</span>
                </div>
                <div className='step_diagn step_input'>
                  <svg className={this.core.state.isAnswer  && !this.core.state.userIsValid  ? 'activated' : null} onClick={(e) => this.core.setState({ isAnswer: true, userIsValid: false, showButtonForAdmin: false })} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m348.41 504.58c186.98 0 342.3-182.57 342.3-182.57 12.148-13.004 12.488-34.48 0.38281-47.59 0 0-147.64-182.07-341.07-182.07-193.43 0-341.07 182.07-341.07 182.07-11.938 13.281-12 34.836 0.21094 47.789 0 0 152.27 182.37 339.25 182.37zm1.6133-60.621c80.121 0 145.07-65.137 145.07-145.49 0-80.352-64.953-145.49-145.07-145.49-80.125 0-145.07 65.141-145.07 145.49 0 80.352 64.949 145.49 145.07 145.49zm0-80.828c35.609 0 64.477-28.949 64.477-64.66 0-35.711-28.867-64.664-64.477-64.664-35.609 0-64.48 28.953-64.48 64.664 0 35.711 28.871 64.66 64.48 64.66z" fillRule="evenodd"/></svg>
                  <span style={{fontSize:"12px", margin:'0 0 0 10px'}}>Mauvause réponse</span>
                </div>
              </>)}
            </div>
          </div>
        </div>
        <div className="dropdown_item">
          <div style={{width:'100%'}}>
            <h4 style={{marginBottom:'5px', fontSize:'10px'}}>Questions</h4>
            <div className='steps_diagn'>
              <div  className='step_diagn step_input'>
                <span style={{fontSize:"12px"}}>Categorie: </span>
                <EditableField
                  isLanding={this.core.props.isLanding}
                  tag="p"
                  field={this.core.props.format.categorie ?? 'master'}
                  isEditor={true}
                  type="categorie"
                />
              </div>
              <div  className='step_diagn step_input'>
                <Toggle
                  isLanding={this.core.props.isLanding}
                  type="multi"
                  check={this.core.props.format.multi}
                  label="Question à choix multiple"
                  callback={this.core.onReRender}
                />
              </div>
              <div  className='step_diagn step_input'>
                <Toggle
                  isLanding={this.core.props.isLanding}
                  type="noValidAnswer"
                  check={this.core.props.format.noValidAnswer}
                  label="Question sans bonnes réponses"
                  callback={this.core.onNoValidAnswerChange}
                />
              </div>
            </div>
            <div className='steps_diagn' style={{marginTop:"8px"}}>
              {this.core.props.format.responses.map((item, i)=>(
                <div className=" step_diagn" id={'answer' + i} key={'response-' + i}>
                  <div className='step_input'>
                      <div className="pts_step_diagn" >
                      <EditableField
                        isLanding={this.core.props.isLanding}
                        tag="p"
                        field={item.points ?? 0}
                        onInputChange={(value) => this.handleResponseChange('points',value ?  parseInt(value) : 0, i)}
                        isEditor={this.core.props.isEditor}
                        type="responses"
                      />
                    </div>
                    <div>
                      <input
                        onChange={this.handleCheckEditor}
                        type={this.core.props.format.multi ? 'checkbox' : 'radio'}
                        name={'question_unclcik'}
                        className={'question_editor_admin'}
                        key={i + '-input_unclick'}
                        id={'question' + i + '_unclick'}
                        value={i}
                      />
                      <label className="labelDropdown" htmlFor={'question' + i + '_unclick'}>
                        <span className="unchecked">
                          <span className={'checked' + (item.isValid !== null ? ' checkAnswered': '')}>
                            <CSSTransition
                              in={item.isValid !== null}
                              timeout={0}
                              unmountOnExit
                              appear
                            >
                              <span isvalid={item.isValid ? 'true' : 'false'}>
                                <img className="goodCheck" src={goodCheck} />
                                <img className="badCheck" src={badCheck} />
                              </span>
                            </CSSTransition>
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className='question_diagn_quizz'>

                    <EditableField
                      isLanding={this.core.props.isLanding}
                      tag="p"
                      field={item.reponse}
                      onInputChange={(value) => this.handleResponseChange('reponse',value, i)}
                      isEditor={this.core.props.isEditor}
                      type="responses"
                      />
                    </div>
                  </div>
                  <div className='step_input'>
                    <svg  className={'diagn_delete'} onClick={()=> this.removeResponse(i)} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m491.51 177.63h-283.02c-4.8047-0.03125-9.3945 1.9961-12.605 5.5742-3.2148 3.5742-4.7383 8.3555-4.1953 13.129l28 244.5c2.0234 17.77 10.516 34.176 23.859 46.086 13.348 11.906 30.609 18.488 48.492 18.48h120.57c18.113 0 35.574-6.7539 48.973-18.945 13.402-12.188 21.777-28.934 23.488-46.965l23.188-243.49c0.44141-4.7188-1.1328-9.4062-4.332-12.906s-7.7305-5.4844-12.469-5.4609z"/><path d="m424.48 93.801c0-10.398-4.1289-20.367-11.48-27.719-7.3516-7.3516-17.324-11.48-27.719-11.48h-70.562c-10.395 0-20.367 4.1289-27.719 11.48-7.3516 7.3516-11.48 17.32-11.48 27.719v14.785h-124.77v33.602l398.49-0.003906v-33.602l-124.77 0.003907zm-33.602 14.785h-81.758v-14.785c0-3.0938 2.5078-5.6016 5.5977-5.6016h70.562c1.4844 0 2.9102 0.58984 3.957 1.6406 1.0508 1.0508 1.6406 2.4766 1.6406 3.9609z"/></svg>
                    {i !== 0 && <div onClick={()=> this.moveCat(i,-1)} className="tableNav">▲</div>}
                    {this.core.props.format.responses.length > 1 && i !== this.core.props.format.responses.length - 1 && <div onClick={()=> this.moveCat(i,+1)} className="tableNav">▼</div>}

                  </div>
                </div>
              ))}
              <div  className='step_diagn' onClick={() => this.addResponse()}>
                <div style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                  <img className='add_diag_seuil' src={add} />
                  <span style={{fontSize:"12px", marginLeft:'5px'}}>Ajoutez une réponse</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ImageSetting
          isLanding={this.core.props.isLanding}
          image={this.core.props.format.image}
          onReRender={this.core.onReRender}
        />
      </Dropdown>
    )
  }
}

export default QuizzDropdown
