import React, { Component } from 'react'
import { connect } from 'react-redux'
import Toggle from 'react-toggle'

import EditableField from '../../../../components/player/helpers/EditableField'
import { onFormationChange } from '../../../actions/playerActions'

import 'react-toggle/style.css'

class ImageSetting extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasImage: this.props.image ? true : false,
      link: this.props.image
        ? this.props.image.link
        : 'https://video.thelab.risinguparis.com/brain.svg',
      type: this.props.image ? this.props.image.type : 'contain', // cover ou contain
    }
  }
  updateFormation = () => {}

  handleChange = (e, type) => {
    var data = { link: this.state.link, type: this.state.type }
    if (type == 'hasImage') {
      this.setState({ hasImage: e.target.checked })
      this.props.onFormationChange(
        'image',
        e.target.checked ? data : null,
        this.props.index,
        this.props.isLanding ? this.props.landing : this.props.formation,
        this.props.isLanding ? true : false
      )
    } else if (type == 'type') {
      data['type'] = e.target.checked ? 'cover' : 'contain'
      this.setState({ type: data['type'] })
      if (this.state.hasImage)
        this.props.onFormationChange(
          'image',
          data,
          this.props.index,
          this.props.isLanding ? this.props.landing : this.props.formation,
          this.props.isLanding ? true : false
        )
    }
    this.props.onReRender()
  }

  handleLinkChange = (text) => {
    var data = { link: this.state.link, type: this.state.type }
    data['link'] = text
    this.setState({ link: text })
    if (this.state.hasImage)
      this.props.onFormationChange(
        'image',
        data,
        this.props.index,
        this.props.isLanding ? this.props.landing : this.props.formation,
        this.props.isLanding ? true : false
      )
    this.props.onReRender()
    this.props.onReRender()

    return { data: data, index: false }
  }

  render() {
    return (
      <div className="dropdown_item">
        <div style={{width:'100%'}}>
          <h4 style={{marginBottom:'5px', fontSize:'10px'}}>Image</h4>
          <div className='steps_diagn'>
            <div className='step_diagn step_input'>
              <Toggle
                id="image_status"
                checked={this.state.hasImage}
                icons={false}
                onChange={(e) => this.handleChange(e, 'hasImage')}
              />
              <label htmlFor="image_status">Afficher une image</label>
            </div>
            <div className={'step_diagn step_input' + (this.state.hasImage ? '' : ' dropdown_hidden')}>
              <Toggle
                id="image_type"
                checked={this.state.type == 'cover' ? true : false}
                icons={false}
                onChange={(e) => this.handleChange(e, 'type')}
              />
              <label htmlFor="image_type">Elargir l'image</label>
            </div>
            <div  className={'step_diagn step_input' + (this.state.hasImage ? '' : ' dropdown_hidden')}>
              <span style={{fontSize:"12px", minWidth:'fit-content'}}>Url Image: </span>
              <EditableField
                isLanding={this.props.isLanding}
                tag="p"
                onInputChange={this.handleLinkChange}
                field={this.state.link}
                isEditor={true}
                type="link"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  landing: state.playerLanding,
  formation: state.playerFormation,
  index: state.playerIndex,
})

export default connect(mapStateToProps, { onFormationChange })(ImageSetting)
