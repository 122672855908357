import React, { Component } from 'react'

import FormatVideo from './formats/FormatVideo'
import FormatTexte from './formats/FormatTexte'
import FormatTitre from './formats/FormatTitre'
import FormatQuestionnaire from './formats/FormatQuestionnaire'
import FormatQuizz from './formats/FormatQuizz'
import FormatFeedback from './formats/FormatFeedback'
import FormatInscription from './formats/FormatInscription'
import FormatDiagnostic from './formats/FormatDiagnostic'
import FormatQuestionnaireV2 from './formats/FormatQuestionnaireV2'
import FormatInputTexte from './formats/FormatInputTexte'

class FormatSwitch extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    var format = this.props.format
    //console.log(format)

    if (!format) return ''
    switch (format.type) {
      case 'Video':
        return <FormatVideo {...this.props} key={'formatSwitch-' + this.props.index} />
      case 'Titre':
        return <FormatTitre {...this.props} key={'formatSwitch-' + this.props.index} />
      case 'Texte':
        return <FormatTexte {...this.props} key={'formatSwitch-' + this.props.index} />
      case 'Questionnaire':
        return <FormatQuestionnaire {...this.props} key={'formatSwitch-' + this.props.index} />
      case 'QuestionnaireV2':
        return <FormatQuestionnaireV2 {...this.props} key={'formatSwitch-' + this.props.index} />
      case 'Quizz':
        return <FormatQuizz {...this.props} key={'formatSwitch-' + this.props.index} />
      case 'InputTexte':
        return <FormatInputTexte {...this.props} key={'formatSwitch-' + this.props.index} />
      case 'Feedback':
        return <FormatFeedback {...this.props} key={'formatSwitch-' + this.props.index} />
      case 'Diagnostic':
        return <FormatDiagnostic {...this.props} key={'formatSwitch-' + this.props.index} />
      case 'Inscription':
        return <FormatInscription {...this.props} key={'formatSwitch-' + this.props.index} />
    }
    return ''
  }
}
export default FormatSwitch
