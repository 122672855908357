import EditableField from '../../../components/player/helpers/EditableField.js'
import Dropdown from '../../../components/player/helpers/Dropdown.js'
import Toggle from '../../../components/player/helpers/Toggle.js'

import ImageSetting from '../formation/player/ImageSetting.js'
import React, { Component } from 'react'
import add from '../../../img/add.svg'
import { CSSTransition } from 'react-transition-group'
import badCheck from '../../../img/badCheck.svg'
import goodCheck from '../../../img/goodCheck.svg'
import updateFormation from '../../../utils/updateFormation.js'

class QuestionnaireDropdown  extends Component {
  constructor(props){
    super(props)
    this.core = props.core
  }

  handleResponseChange = (key, value, i) => {
    var data = this.core.props.format.responses
    data[i][key] = value
    return { data: data, index: false }
  }

  removeResponse = (i) => {
    var data = this.core.props.format.responses
    data.splice(i, 1)
    updateFormation('responses', data, this.core.props.isLanding)
  }

  addResponse = (e) => {
    var data = this.core.props.format.responses
    data.push({ label: `${this.core.props.format.responses.length}`, points: this.core.props.format.responses.length})
    updateFormation('responses', data, this.core.props.isLanding)
  }

  moveCat = (i, nMove) => {
    var data = this.core.props.format.responses
    const tmp = data.splice(i, 1)
    data.splice(i + nMove, 0, tmp[0])
    updateFormation('responses', data, this.core.props.isLanding)
  }

  render() {
    return (
      <Dropdown>
        <div className="dropdown_item">
          <div style={{width:'100%'}}>
            <h4 style={{marginBottom:'5px', fontSize:'10px'}}>Paliers Slider</h4>
            <div  style={{margin: '0 0 8px'}}className='step_input'>
              <span style={{fontSize:"12px"}}>Categorie: </span>
              <EditableField
                isLanding={this.core.props.isLanding}
                tag="p"
                field={this.core.props.format.categorie ?? 'master'}
                isEditor={true}
                type="categorie"
              />
            </div>
            <div className='steps_diagn'>
              <div className=" step_diagn">
                <div className='step_input_header'>
                    <p style={{marginBottom:'5px', fontSize:'10px'}}>pts</p>
                     <p style={{marginBottom:'5px', fontSize:'10px'}}>Texte</p>
                </div>
                <div></div>
              </div>
              {this.core.props.format.responses.map((item, i)=>(
                <div className=" step_diagn" id={'answer' + i} key={'response-' + i}>
                  <div className='step_input'>
                      <div className="pts_step_diagn" >
                      <EditableField
                        isLanding={this.core.props.isLanding}
                        tag="p"
                        field={item.points ?? 0}
                        onInputChange={(value) => this.handleResponseChange('points',value ?  parseInt(value) : 0, i)}
                        isEditor={this.core.props.isEditor}
                        type="responses"
                      />
                    </div>
                    <div className='question_diagn_quizz'>
                      <EditableField
                        isLanding={this.core.props.isLanding}
                        tag="p"
                        field={item.label}
                        onInputChange={(value) => this.handleResponseChange('label',value, i)}
                        isEditor={this.core.props.isEditor}
                        type="responses"
                        />
                    </div>
                  </div>
                  <div className='step_input'>
                    <svg  className={'diagn_delete'} onClick={()=> this.removeResponse(i)} width="20" height="20" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" ><path d="m491.51 177.63h-283.02c-4.8047-0.03125-9.3945 1.9961-12.605 5.5742-3.2148 3.5742-4.7383 8.3555-4.1953 13.129l28 244.5c2.0234 17.77 10.516 34.176 23.859 46.086 13.348 11.906 30.609 18.488 48.492 18.48h120.57c18.113 0 35.574-6.7539 48.973-18.945 13.402-12.188 21.777-28.934 23.488-46.965l23.188-243.49c0.44141-4.7188-1.1328-9.4062-4.332-12.906s-7.7305-5.4844-12.469-5.4609z"/><path d="m424.48 93.801c0-10.398-4.1289-20.367-11.48-27.719-7.3516-7.3516-17.324-11.48-27.719-11.48h-70.562c-10.395 0-20.367 4.1289-27.719 11.48-7.3516 7.3516-11.48 17.32-11.48 27.719v14.785h-124.77v33.602l398.49-0.003906v-33.602l-124.77 0.003907zm-33.602 14.785h-81.758v-14.785c0-3.0938 2.5078-5.6016 5.5977-5.6016h70.562c1.4844 0 2.9102 0.58984 3.957 1.6406 1.0508 1.0508 1.6406 2.4766 1.6406 3.9609z"/></svg>
                    {i !== 0 && <div onClick={()=> this.moveCat(i,-1)} className="tableNav">▲</div>}
                    {this.core.props.format.responses.length > 1 && i !== this.core.props.format.responses.length - 1 && <div onClick={()=> this.moveCat(i,+1)} className="tableNav">▼</div>}
                  </div>
                </div>
              ))}
              <div  className='step_diagn' onClick={() => this.addResponse()}>
                <div style={{display:'flex', alignItems:'center', cursor:'pointer'}}>
                  <img className='add_diag_seuil' src={add} />
                  <span style={{fontSize:"12px", marginLeft:'5px'}}>Ajoutez un palier</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Dropdown>
    )
  }
}

export default QuestionnaireDropdown
