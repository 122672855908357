import Format from './Format.js'
import { connect } from 'react-redux'

import QuestionInputTexte from '../helpers/QuestionInputTexte.js'
import MaskQuizz from '../helpers/MaskQuizz.js'
import MaskText from '../helpers/MaskText.js'
import Button from '../Button'
import React, { Component } from 'react'

import EditableField from '../helpers/EditableField.js'
import EditableArea from '../helpers/EditableArea.js'

import { onFormationChange } from '../../../admin/actions/playerActions.js'
import InputTexteDropdown from '../../../admin/components/dropdown/InputTexteDropdown.js'

class FormatInputTexte extends Format {
  constructor(props) {
    super(props)

    var isAnswer = true
    if (this.props.format.userChoice === null || this.props.format.userChoice === undefined ) {
      isAnswer = false
    }

    this.state = {
      isAnswer: isAnswer,
      userChoice: isAnswer ? this.props.format.userChoice : null,
      showButtonForAdmin: props.isEditor ? true : false,
    }
  }

  componentDidUpdate() {

  }

  onAnswer = (response) => {
    this.props.onDataChange([{ key: 'userChoice', value: response }])
    this.setState({ isAnswer: true, userChoice: response, })
    this.manageResponse()
  }

  renderQuestion = () => {
    var image = this.props.format.image

    return (
      <>
        <QuestionInputTexte
          button={this.props.format.button}
          isAnswer={this.state.isAnswer}
          userChoice={this.state.userChoice}
          isEditor={this.props.isEditor}
          isLanding={this.props.isLanding}
          showButtonForAdmin={this.state.showButtonForAdmin}
          answers={this.props.format.answers}
          image={this.props.format.image}
          onAnswer={this.onAnswer}
        />
      </>
    )
  }

  render() {
    return (
      <div className="format_quizz formatBox">
        <div className="format_wrapper">
          <div>
            {this.props.isEditor || this.props.format.exerciceTitle ? (
              <EditableField
                isLanding={this.props.isLanding}
                tag="h1"
                classTag="xs"
                field={this.props.format.exerciceTitle}
                isEditor={this.props.isEditor}
                type="exerciceTitle"
              />
            ) : (
              <>{''}</>
            )}
          </div>
          <div>
            <EditableField
              isLanding={this.props.isLanding}
              tag="h5"
              field={this.props.format.questions}
              isEditor={this.props.isEditor}
              type="questions"
            />
            {this.props.format.image && this.renderQuestion()}
          </div>
          <div></div>
        </div>
        {this.props.format.image ? (
          <MaskText image={this.props.format.image} />
        ) : (
          <MaskQuizz className="maskQuizz">{this.renderQuestion()}</MaskQuizz>
        )}
        {this.props.isEditor && (
          <>
            {<InputTexteDropdown core={this}/>}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  formation: state.playerFormation,
  landing: state.playerLanding,
  index: state.playerIndex,
  playerReduxBug: state.playerReduxBug,
})

export default connect(mapStateToProps, { onFormationChange })(FormatInputTexte)
