import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'

import { setLandingResult, setLandingScore } from '../../actions/landingPageActions'

import { getLandingPage } from '../../actions/landingPageActions'
import LanguageContext from "../../contexts/LanguageContext"

import Waiting from '../layout/Waiting'
import Background from '../svg/BackGroundAnimated'
import EmptyHeader from '../layout/EmptyHeader'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import _Score from './_Score'
import { isTablet, isMobile } from 'react-device-detect';

import '../homepage/css/labs.css'
import Player from '../player/Player'
class LandingPage extends Component {
  constructor(props) {
    super(props)
    if (props.landingPage === null) {
      props.getLandingPage(this.props.match.params.slug)
    }
    this.state = {
      introIsEnded: false,
      quoteIsEnded: false,
      gameIsEnded: false,
      index: 0,
      orientation : null,
      viewOrientationModalTablet : false,
      viewOrientationModalMobile : false,
    }

  }

  onChange = (e) => {
    this.setState({ [e.name]: e.value })
  }

  onDataChange = (content) => {
    this.props.setLandingResult(content)
  }

  onIndexChange = (i) => {
    if (i === this.props.landingPage.experiences.length-1) {
      //on est sur le dernier format, il faut enregistrer les résultats
      this.props.setLandingScore(
        this.props.landingPage.id,
        this.props.landingPage.slug,
        this.props.landingUser,
        this.props.landingPage.experiences,
        () => {}
      )
    }
    if (i !== 'next') return this.setState({ index: i })

    if (i === "next") {
      return this.setState({ gameIsEnded: true })
    }

  }

  componentDidMount() {
    sessionStorage.setItem('saveLandingUserID', null);

    window.addEventListener('resize', this.updateSize);
    this.updateSize()
    return () => window.removeEventListener('resize', this.updateSize);
  }

  updateSize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    let orientation = null;
    if (window.innerWidth >= window.innerHeight) {
      orientation = "landscape"
    } else {
      orientation = "portrait"
    }
    if (this.state.orientation !== orientation) {
      this.setState({
        orientation : orientation
      })
      if (isTablet && orientation === "portrait") {
        this.setState({
          viewOrientationModalTablet : true
        })
      }
      if (isMobile && orientation === "landscape") {
        this.setState({
          viewOrientationModalMobile : true
        })
      }
      if (orientation === "landscape") {
        this.setState({
          viewOrientationModalTablet : false
        })
      }
      if (orientation === "portrait") {
        this.setState({
          viewOrientationModalMobile : false
        })
      }
    }
  }

  render() {
    setTimeout(function () {
      window.scrollTo(0, 1)
    }, 100)
    if (this.props.landingPage === null) return <Waiting />

    return (
      <>
        <Dialog
          open={this.state.viewOrientationModalTablet}
          onClose={() => {this.setState({viewOrientationModalTablet : false})}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            position: 'absolute',
            top : 0,
            left :0,
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {this.context.values.formation.screenOrientationTitle[this.context.language]}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.context.values.formation.screenOrientationText[this.context.language]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {this.setState({viewOrientationModalTablet : false})}}
              autoFocus
            >
              {this.context.values.formation.screenOrientationBtn[this.context.language]}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.viewOrientationModalMobile}
          onClose={() => {this.setState({viewOrientationModalMobile : false})}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            position: 'absolute',
            top : 0,
            left :0,
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {this.context.values.formation.screenOrientationTitle[this.context.language]}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.context.values.formation.screenOrientationTextMobile[this.context.language]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {this.setState({viewOrientationModalMobile : false})}}
              autoFocus
            >
              {this.context.values.formation.screenOrientationBtn[this.context.language]}
            </Button>
          </DialogActions>
        </Dialog>


        <Helmet>
          <title>{this.props.landingPage.titre + ' by Rising Up'}</title>
          <meta name="description" content={this.props.landingPage.metaDescription} />
        </Helmet>
        <div className="landingPage">
          <Background
            titre={this.props.landingPage.titre}
            introIsEnded={this.state.gameIsEnded ? false : this.state.introIsEnded}
            quoteIsEnded={this.state.gameIsEnded ? false : this.state.quoteIsEnded}
            color={this.props.color}
          />
          <EmptyHeader />
          <style>
            {'.buttonValidate ,  button.mauticform-button {background: ' +
              this.props.color.color +
              ' !important;}'}
            {'button.buttonValidate.previous  {background: ' + this.props.color.color + '66;}'}
          </style>
          <div
            className={this.state.quoteIsEnded ? 'formation landing isStarted' : 'formation landing'}
          >
            <div className="formation_wrapper">
              <div className="player_wrapper">
                {this.state.gameIsEnded ? (
                  <_Score
                    landing={this.props.landingPage}
                    onChange={this.onChange}
                    gameIsEnded={this.state.gameIsEnded}
                    email={this.state.email}
                  />
                ) : (
                  <Player
                    key={'player_landing'}
                    color={this.props.color}
                    onDataChange={this.onDataChange}
                    onIndexChange={this.onIndexChange}
                    firstIndex={this.state.index}
                    title={this.props.landingPage.titre}
                    content={this.props.landingPage.experiences}
                    isLanding={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  landingPage: state.landingPage,
  landingUser: state.landingUser,
  color: state.color,
})
export default connect(mapStateToProps, { getLandingPage, setLandingResult, setLandingScore })(
  LandingPage
)
LandingPage.contextType = LanguageContext
