import React, { Component } from 'react'

import FormatSwitch from './FormatSwitch'
import './css/global.css'

class Player extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: props.firstIndex,
      userContent: props.content,
      transitioning: true,
      openingAnimtation: true,
    }

    setTimeout(() => {
      this.setState({ openingAnimtation: false })
    }, 200)
    setTimeout(() => {
      this.setState({ transitioning: false })
    }, 2500)
  }

  manageSlide = (index) => {
    var newIndex = this.state.index + index
    if (newIndex < 0) return null
    if (newIndex >= this.state.userContent.length) return this.props.onIndexChange('next')

    this.transition(newIndex)
    return this.props.onIndexChange(newIndex)
  }

  onDataChange = (e) => {
    var content = this.state.userContent
    for(const d of e){
      content[this.state.index][d.key] = d.value
    }
    this.setState({ userContent: content })
    this.props.onDataChange(content)
  }

  transition = (i) => {
    this.setState({ index: i, transitioning: true })
    setTimeout(() => {
      this.setState({ transitioning: false })
    }, 200)
  }

  render() {
    var format = this.state.userContent[this.state.index]
    var isOpen = true
    return (
      <div
        className={
          this.state.openingAnimtation
            ? 'player player_opening'
            : this.state.transitioning
            ? 'player format_transition'
            : 'player'
        }
      >
        <style>
          {'.playerTitle {background: linear-gradient(90deg, ' +
            this.props.color.color +
            ' 0%, ' +
            this.props.color.color +
            '00  60%);}'}
          {'.formatBox{background:  linear-gradient(135deg,' +
            this.props.color.grayLinear.first +
            ',' +
            this.props.color.grayLinear.last +
            ')}'}
          {'.format_dot.format_dot_isdone {background: ' + this.props.color.color + '}'}
          {'.btn_wrapper button.btn_primary {background: ' + this.props.color.color + '}'}
          {'.MuiSlider-thumbColorPrimary {background: ' + this.props.color.color + ' !important}'}
          {'input[type="radio"]:checked + label .checked,input[type="checkbox"]:checked + label .checked {background: ' +
            this.props.color.color +
            '}'}
          {'.loader-ring-light {box-shadow: 0 4px 0 ' + this.props.color.color + ' inset !important; }'}
        </style>
        <div className="playerTitle">
          <h2>{this.props.title}</h2>
        </div>
        <FormatSwitch
          allFormation={this.props.allFormation}
          format={format}
          chapitre={this.props.chapitre}
          index={this.state.index}
          userContent={this.state.userContent}
          key={'format' + this.props.index}
          manageSlide={this.manageSlide}
          onDataChange={this.onDataChange}
          isLanding={this.props.isLanding}
        />
        <div className="player_nav">
          {this.state.userContent.map((format, i) => {
            var classText = 'format_dot '
            if (format.isDone || isOpen) {
              classText += 'format_dot_isdone '
              if (!format.isDone) isOpen = false
            }
            if (i == this.state.index) classText += 'format_dot_doing'
            return (
              <div
                key={'format_dot' + i}
                className={classText}
                onClick={() => {
                  if (classText !== 'format_dot ') this.transition(i)
                }}
              ></div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default Player
