import React, { Component, useState } from 'react'

import more from '../../../img/more.svg'
import add from '../../../img/add.svg'

class Dropdown extends Component {
  constructor(props) {
    super(props)

    this.state = { isOpen: false }
  }

  render() {
    return (
      <div className="dropdown_wrapper" style={{
        right: this.props.side === "left" && this.state.isOpen ? '-300px' : 0,
        alignItems : this.props.side === "left" ? 'flex-start' : 'flex-end',
        transition : 'none',
      }}>
        {this.state.isOpen ? (
          <img
            src={add}
            style={{
              transform: 'rotate(45deg)',
              position : 'relative',
              left : this.props.side === "left" ? '-40px' : 0,
            }}
            onClick={() => {
              this.setState({ isOpen: false })
            }}
          />
        ) : (
          <img
            src={more}
            onClick={() => {
              this.setState({ isOpen: true })
            }}
          />
        )}
        {this.state.isOpen &&
          <div className="dropdown_content" style={{
            position: 'relative',
            right: this.props.side === "left" ? '0' : 0,
          }}>
            {this.props.children}
          </div>
        }
      </div>
    )
  }
}

export default Dropdown
