const landingPageReducer = (state = null, action) => {
  switch (action.type) {
    case 'FETCH_PAGE':
      return action.data
    case 'SET_PAGE_RESULT':
      return {
        ...state,
        experiences : action.data,
        /*gameJSON: {
          ...state.gameJSON,
          experiences: action.data,
        },*/
      }
    case 'SET_PAGE_SCORE':
      return {
        ...state,
        core : action.data,
        /*gameJSON: {
          ...state.gameJSON,
          score: action.data,
        },*/
      }
    default:
      return state
  }
}

export default landingPageReducer
