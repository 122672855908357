import { combineReducers } from 'redux'
import authReducer from './authReducers'
import errorReducer from './errorReducers'
import formationReducer from './formationReducer'
import UserIndexReducer from './UserIndexReducer'
import progressReducer from './progressReducer'
import datesReducer from './datesReducer'
import userReducer from './userReducer'
import planReducer from './planReducer'
import usersGroupeReducer from './usersGroupeReducer'
import playAdminReducer from './playAdminReducer'
import landingPageReducer from './landingPageReducer'
import landingUserReducer from './landingUserReducer'
import colorReducer from './colorReducer'

import formationsReducer from '../admin/reducers/formationsReducer'
import groupeReducer from '../admin/reducers/groupeReducer'
import landingPagesReducer from '../admin/reducers/landingPagesReducer'
import exercicesReducer from '../admin/reducers/exercicesReducer'
import colorsReducer from '../admin/reducers/colorsReducer'
import adminClientsReducer from '../admin/reducers/adminClientsReducer'
import playerIndexReducer from '../admin/reducers/playerIndexReducer'
import playerHasChangeReducer from '../admin/reducers/playerHasChangeReducer'
import playerFormationReducer from '../admin/reducers/playerFormationReducer'
import playerLandingReducer from '../admin/reducers/playerLandingReducer'
import playerReduxBugReducer from '../admin/reducers/playerReduxBugReducer'

import adminGroupesReducer from './adminGroupesReducer'

import { DESTROY_SESSION } from '../actions/types'
const appReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,

  formations: formationsReducer,
  formation: formationReducer,
  actualIndex: UserIndexReducer,
  progress: progressReducer,
  dates: datesReducer,
  user: userReducer,
  plan: planReducer,
  groupe: groupeReducer,
  usersGroupe: usersGroupeReducer,
  playAdmin: playAdminReducer,
  landingPage: landingPageReducer,
  landingUser: landingUserReducer,
  landingPages: landingPagesReducer,
  exercices: exercicesReducer,
  colors: colorsReducer,
  color: colorReducer,
  adminClients : adminClientsReducer,
  playerIndex: playerIndexReducer,
  playerHasChange: playerHasChangeReducer,
  playerFormation: playerFormationReducer,
  playerLanding: playerLandingReducer,
  playerReduxBug: playerReduxBugReducer,

  adminGroupes : adminGroupesReducer,
})

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) state = undefined

  return appReducer(state, action)
}
export default rootReducer
