import React, { Component } from 'react'

class FormatStepEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      steps: props.steps,
      index: 0,
    }
  }

  onClick = (index) => {
    this.setState({ index: index })
    this.state.steps[index].callback()
  }

  render() {
    return (
      <div className="admin_format_steps">
        {this.state.steps.map((step, index) => {
          return (
            <div
              onClick={() => {
                this.onClick(index)
              }}
              key={'step_format_editor_' + index}
              className={this.state.index == index ? 'admin_format_step_active' : ''}
            >
              {step.text ? step.text : <img src={step.img} />}
            </div>
          )
        })}
      </div>
    )
  }
}

export default FormatStepEditor
