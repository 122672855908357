import axios from 'axios'

import {
  ADD_POST,
  EDIT_POST,
  UPDATE,
  ACTUAL_INDEX,
  DELETE_POST,
  FETCH_ADMIN,
  FETCH_GROUPE,
  USERSGROUP,
  PLAY_ADMIN,
  FETCH_FORMATION,
  FETCH_PAGES,
  PAGEID,
  FETCH_COLORS,
  FETCH_EXERCICES,
  GLB_URL_APIS,
  FETCH_ADMINCLIENTS,
} from '../../actions/types'

import { ADMIN_PLAYER_HAS_CHANGE } from './types'

let jsondiffpatch = require("jsondiffpatch");


export const getContentById = async (vals) => {
  try {
    const res = await axios.post(GLB_URL_APIS + "/content/admin/getOneById", vals, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}

export const editFormationElement = async (type, vals) => {
  try {
    const res = await axios.post(GLB_URL_APIS + "/content/admin/"+type+"/edit", vals, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}

export const addFormationElement = async (type, idToAdd, vals) => {
  let values = {idToAdd : idToAdd, vals : vals}
  try {
    const res = await axios.post(GLB_URL_APIS + "/content/admin/"+type+"/add", values, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}

export const removeFormationElement = async (type, idToRemove) => {
  let vals = {id : idToRemove}
  try {
    const res = await axios.post(GLB_URL_APIS + "/content/admin/"+type+"/remove", vals, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}

export const editLandingElement = async (type, vals) => {
  try {
    const res = await axios.post(GLB_URL_APIS + "/page/admin/"+type+"/edit", vals, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}

export const addLandingElement = async (type, idToAdd, vals) => {
  let values = {idToAdd : idToAdd, vals : vals}
  try {
    const res = await axios.post(GLB_URL_APIS + "/page/admin/"+type+"/add", values, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}

export const removeLandingElement = async (type, idToRemove) => {
  let vals = {id : idToRemove}
  try {
    const res = await axios.post(GLB_URL_APIS + "/page/admin/"+type+"/remove", vals, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}



export const getAdminData = () => (dispatch) => {
  getAdminGroupe(dispatch)
  getAdminFormation(dispatch)
  getAdminColor(dispatch)
  getAdminLandingPages(dispatch)
  getAdminAdminClients(dispatch)
  /*axios
    .post(GLB_URL_APIS + '/api/admin/data')
    .then((res) => {
      dispatch({
        type: FETCH_ADMIN,
        data: res.data.formations,
      })
      dispatch({
        type: FETCH_GROUPE,
        data: res.data.groupes,
      })
      dispatch({
        type: FETCH_COLORS,
        data: res.data.colors,
      })
      dispatch({
        type: FETCH_EXERCICES,
        data: res.data.exercices,
      })
      dispatch({
        type: FETCH_PAGES,
        data: res.data.pages,
      })
    })
    .catch((err) => console.log(err))*/
}

export const createFormation = ($data, $history) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/content/admin/add', $data)
    .then((res) => {
      console.log(res)
      dispatch({
        type: FETCH_ADMIN,
        data: res.data.formations,
      })
      $history.push('/admin/formation/player/' + res.data.id)
    })
    .catch((err) => console.log(err))
}

export const reloadAdminContent = (archived) => (dispatch) => {
  getAdminFormation(dispatch, archived)
}

export const getAdminFormation = (dispatch, archived) => {
  let vals = {}
  if (archived === true) {
    vals.archived = true;
  }
  axios
    .post(GLB_URL_APIS + '/content/admin', vals)
    .then((res) => {
      //console.log(res.data.formations)
      dispatch({
        type: FETCH_ADMIN,
        data: res.data.formations,
      })
    })
    .catch((err) => console.log(err))
}

export const reloadAdminGroupe = (archived) => (dispatch) => {
  getAdminGroupe(dispatch, archived)
}

export const getAdminGroupe = (dispatch, archived) => {

  let vals = {}
  if (archived === true) {
    vals.archived = true;
  }
  axios
    .post(GLB_URL_APIS + '/groupe/admin', vals)
    .then((res) => {
      var newData = res.data
      dispatch({
        type: FETCH_GROUPE,
        data: newData,
      })
    })
    .catch((err) => console.log(err))
}

export const getAdminColor = (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/color/admin')
    .then((res) => {
      dispatch({
        type: FETCH_COLORS,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}

export const getAdminAdminClients = (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/user/admin/adminclient')
    .then((res) => {
      dispatch({
        type: FETCH_ADMINCLIENTS,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}

export const getUsersResults = async (values) => {
  try {
    const res = await axios.post(GLB_URL_APIS + "/groupe/admin/usersResults", values, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}
export const getUsersResultsLanding = async (values) => {
  try {
    const res = await axios.post(GLB_URL_APIS + "/page/admin/getAllUsers", values, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}
export const getResultsLanding = async (values) => {
  try {
    const res = await axios.post(GLB_URL_APIS + "/page/admin/getAllResults", values, {
      withCredentials: true,
    })
    return res.data
  } catch (err) {
    return console.log(err)
  }
}

export const setUserid = (groupid) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/groupe/admin/users', { id: groupid })
    .then((res) => {
      dispatch({
        type: USERSGROUP,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}
export const createGroupe =
  ($data, $callback = null) =>
  (dispatch) => {
    axios
      .post(GLB_URL_APIS + '/groupe/admin/add', $data)
      .then((res) => {
        dispatch({
          type: FETCH_GROUPE,
          data: res.data,
        })
        if ($callback) $callback()
      })
      .catch((err) => console.log(err))
  }
export const updateGroupe = ($data) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/groupe/admin/edit', $data)
    .then((res) => {
      dispatch({
        type: FETCH_GROUPE,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}
export const archiveOrNotGroupe = ($data) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/groupe/admin/archiveOrNot', $data)
    .then((res) => {
      dispatch({
        type: FETCH_GROUPE,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}

export const archiveOrNotContent = ($data, $history) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/content/admin/archiveOrNot', $data)
    .then((res) => {
      dispatch({
        type: FETCH_ADMIN,
        data: res.data.formations,
      })
      $history.push('/admin/formation')
    })
    .catch((err) => console.log(err))
}

export const iceBreakerOrNot = ($data) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/content/admin/iceBreakerOrNot', $data)
    .then((res) => {
      if (res.data.error) {
        if (res.data.error === "icebreaker_exist") {
          let text = "Erreur ! Cette formation est utilisée en icebreaker dans le(s) groupe(s) suivant(s) :";
          res.data.groupes.forEach(groupe => {
            text+="\n - "+groupe
          })
          alert(text)
        } else if (res.data.error === "formation_exist") {
          let text = "Erreur ! Cette formation est utilisée dans le(s) groupe(s) suivant(s) :";
          res.data.groupes.forEach(groupe => {
            text+="\n - "+groupe
          })
          alert(text)
        }
      } else {
        dispatch({
          type: FETCH_ADMIN,
          data: res.data.formations,
        })
      }
    })
    .catch((err) => console.log(err))
}

const sequencesDataRefacto = (seq) => {
  if (seq.button && seq.button !== null) {
    try {
      seq.button = JSON.stringify(seq.button)
    } catch(e) { }
  }
  if (seq.buttonStart && seq.buttonStart !== null) {
    try {
      seq.buttonStart = JSON.stringify(seq.buttonStart)
    } catch(e) { }
  }
  if (seq.buttonNext && seq.buttonNext !== null) {
    try {
      seq.buttonNext = JSON.stringify(seq.buttonNext)
    } catch(e) { }
  }
  if (seq.image && seq.image !== null) {
    try {
      seq.image = JSON.stringify(seq.image)
    } catch(e) { }
  }
  if (seq.responses && seq.responses !== null) {
    try {
      seq.responses = JSON.stringify(seq.responses)
    } catch(e) {
      seq.responses = seq.responses
    }
  }
  if (seq.answers && seq.answers !== null) {
    try {
      seq.answers = JSON.stringify(seq.answers)
    } catch(e) { }
  }
  if (seq.total && seq.total !== null) {
    try {
      seq.total = JSON.stringify(seq.total)
    } catch(e) { }
  }
  if (seq.categorie && seq.categorie !== null) {
    try {
      seq.categorie = JSON.stringify(seq.categorie)
    } catch(e) {
      seq.categorie = seq.categorie
    }
  }

  return seq
}

export const setAdminFormation = ($json, $name) => async (dispatch, getState) => {
  if (document.getElementById('overlaySaveFormation')) {
    document.getElementById('overlaySaveFormation').style.display="block"
  }
  dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: false })

  let actFormation = JSON.parse(JSON.stringify($json))
  let contentID = actFormation.id;
  let compareForSave = JSON.parse(sessionStorage.getItem('saveFormationAdmin'))

  //on verifie encore une fois les position de chaque element
  actFormation.feedbackFin = JSON.stringify(actFormation.feedbackFin) //pour le feedback Fin
  actFormation.chapitre.forEach((chap, indexChap) => {
    chap.position = indexChap;
    chap.partie.forEach((part, indexPart) => {
      part.position = indexPart;
      part.sequence.forEach((seq, indexSeq) => {
        seq.position = indexSeq;
        seq = sequencesDataRefacto(seq)
      })
    })
  })

  //on verifie encore une fois les position de chaque element
  compareForSave.feedbackFin = JSON.stringify(compareForSave.feedbackFin) //pour le FeedbackFin
  compareForSave.chapitre.forEach((chap, indexChap) => {
    chap.position = indexChap;
    chap.partie.forEach((part, indexPart) => {
      part.position = indexPart;
      part.sequence.forEach((seq, indexSeq) => {
        seq.position = indexSeq;
        seq = sequencesDataRefacto(seq)
      })
    })
  })

  console.log(JSON.stringify(actFormation))
  console.log(JSON.stringify(compareForSave))

  var diffpatcher = jsondiffpatch.create({
    cloneDiffValues: true,
    propertyFilter: function(name, context) {
      if (name !== 'color') {
        return name;
      }
    },
    objectHash: function(obj, index) {
      return obj.id || '$$index:' + index;
    },
    arrays: {
        // default true, detect items moved inside the array (otherwise they will be registered as remove+add)
        detectMove: true,
        // default false, the value of items moved is not included in deltas
        includeValueOnMove: false,
    },
    textDiff: {
        minLength: 99999999999999,
    },
  });
  let delta = diffpatcher.diff(compareForSave, actFormation);
  if (delta) {
    let deltaFormat = jsondiffpatch.formatters.jsonpatch.format(delta)
    let changesArray = []
    let secureDeletesArray = []
    //console.log(deltaFormat)
    deltaFormat.forEach((change) => {
      if (change.op === "replace") {
        if (change.path === "/feedbackFin") {
          let feedbackFin = JSON.parse(change.value)
          changesArray.push(async function() {
            return await editFormationElement(
              'content',
              {
                id : contentID,
                feedbackTitle : feedbackFin.titre,
                feedbackFormID : feedbackFin.formulaire,
                feedbackSeuil : feedbackFin.seuil,
                feedbackBtnType : feedbackFin.button.type,
                feedbackBtnText : feedbackFin.button.text,
                feedbackSucces : feedbackFin.succes,
                feedbackFail : feedbackFin.fail
              }
            )
          })
        } else {
          let infosUpdate = deltaPathToElementReplace(actFormation, change.path)
          if (infosUpdate.type) {
            console.log("change "+infosUpdate.type+" with id "+infosUpdate.el.id+", key "+infosUpdate.key+" to "+change.value)
            changesArray.push(async function() {
              return await editFormationElement(
                infosUpdate.type,
                {
                  id : infosUpdate.el.id,
                  [infosUpdate.key] : change.value
                }
              )
            })
          }
        }
      } if (change.op === "add") {
        let infosAdd = deltaPathToElementAdd(actFormation, change.path)
        change.value.position = infosAdd.position
        delete change.value.id;
        delete change.value.partieID;
        delete change.value.chapterID;
        if (infosAdd.type) {
          console.log("add "+infosAdd.type+" to element with id "+infosAdd.el.id+", with value "+JSON.stringify(change.value))
          if (infosAdd.type === "sequence" || infosAdd.type === "partie" || infosAdd.type === "chapitre") {
            changesArray.push(async function() {
              return await addFormationElement(
                infosAdd.type,
                infosAdd.el.id,
                change.value
              )
            })
          }
        }
      } if (change.op === "remove") {
        let infosRemove = deltaPathToElementRemove(compareForSave, change.path)
        if (infosRemove.type) {
          console.log("remove "+infosRemove.type+" with id "+infosRemove.el.id)
          if (infosRemove.type === "sequence" || infosRemove.type === "partie" || infosRemove.type === "chapitre") {
            secureDeletesArray.push({
              type : infosRemove.type,
              id : infosRemove.el.id
            })
            changesArray.push(async function() {
              return await removeFormationElement(
                infosRemove.type,
                infosRemove.el.id
              )
            })
          }
        }
      }
    })

    if (changesArray.length > 0) {
      let textConfirm = "Confirmez-vous la suppression des éléments suivants ?\n"
      if (secureDeletesArray.length > 0) {
        for (let i=0; i<secureDeletesArray.length;i++) {
          textConfirm += "- " + secureDeletesArray[i].type + " avec l'ID " + secureDeletesArray[i].id + "\n";
        }
      }
      if (secureDeletesArray.length === 0 || window.confirm(textConfirm) == true) {
        const results = await Promise.all(changesArray.map(f => f()));
        let vals = {}
        if (actFormation.archived === "true") {
          vals.archived = true;
        }
        axios
          .post(GLB_URL_APIS + '/content/admin', vals)
          .then((res) => {
            let thisFormation = res.data.formations.find((el) => el.id === contentID)
            thisFormation.forceRefresh = true;
            dispatch({
              type: FETCH_ADMIN,
              data: [...res.data.formations],
            })
            dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: false })
          })
      } else {
        document.location.reload()
      }

    }


    /*let previousData = getState().formations
    let thisFormation = previousData.find((el) => el.id === contentID)
    thisFormation.forceRefresh = true;
    dispatch({
      type: FETCH_ADMIN,
      data: [...previousData],
    })*/

    //console.log(deltaFormat)
    //console.log(delta)
    //console.log(jsondiffpatch.formatters.console.format(delta))
    //console.log(jsondiffpatch.formatters.jsonpatch.format(delta))
  } else {
    console.log("no change")
    if (document.getElementById('overlaySaveFormation')) {
      document.getElementById('overlaySaveFormation').style.display="none"
    }
  }


  /*axios
    .post(GLB_URL_APIS + '/api/admin/formation/set', { data: $json, name: $name })
    .then((res) => {
      if (!res.data) {
        console.log($json)
        alert('Erreur sauveguarde - Contacter Tim - Recharger la page')
      }
      dispatch({
        type: FETCH_ADMIN,
        data: res.data.formations,
      })
    })
    .catch((err) => {
      console.log(err)
      console.log($json)
      alert('Erreur sauveguarde - Contacter Tim  - Recharger la page')
    })*/
}

const deltaPathToElementRemove = (formation, path) => {
  let pathArr = path.substring(1).split('/')
  let element = {...formation};

  for (let i=0; i<pathArr.length; i++) {
    element = element[pathArr[i]]
  }

  return ({el : element, type : pathArr[pathArr.length-2]})
}

const deltaPathToElementAdd = (formation, path) => {
  let pathArr = path.substring(1).split('/')
  let element = {...formation};

  for (let i=0; i<pathArr.length-2; i++) {
    element = element[pathArr[i]]
  }

  return ({el : element, type : pathArr[pathArr.length-2], position : pathArr[pathArr.length-1]})
}

const deltaPathToElementReplace = (formation, path) => {
  let pathArr = path.substring(1).split('/')
  let keyToChange = pathArr[pathArr.length - 1]
  let element = {...formation};

  for (let i=0; i<pathArr.length-1; i++) {
    element = element[pathArr[i]]
  }

  return ({el : element, type : pathArr[pathArr.length-3] || 'content', key : keyToChange})
}

const deltaPathToElementReplaceLanding = (formation, path) => {
  let pathArr = path.substring(1).split('/')
  let keyToChange = pathArr[pathArr.length - 1]
  let element = {...formation};

  for (let i=0; i<pathArr.length-1; i++) {
    element = element[pathArr[i]]
  }

  return ({el : element, type : pathArr[pathArr.length-3] || 'landing', key : keyToChange})
}

export const archiveOrNotLandingPage = ($data) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/page/admin/archiveOrNot', $data)
    .then((res) => {
      dispatch({
        type: FETCH_PAGES,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}


export const reloadAdminLandingPages = (archived) => (dispatch) => {
  getAdminLandingPages(dispatch, archived)
}

export const getAdminLandingPages = (dispatch, archived) => {
  let vals = {}
  if (archived === true) {
    vals.archived = true;
  }
  axios
    .post(GLB_URL_APIS + '/page/admin', vals)
    .then((res) => {
      var newData = res.data
      dispatch({
        type: FETCH_PAGES,
        data: newData,
      })
    })
    .catch((err) => console.log(err))
}

export const setLandingPageId = (id) => (dispatch) => {
  dispatch({
    type: PAGEID,
    data: id,
  })
}

export const createLandingPage = ($data) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/page/admin/landing/add', $data)
    .then((res) => {
      dispatch({
        type: FETCH_PAGES,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}

export const updateLandingPage = ($data) => async (dispatch) => {
  if (document.getElementById('overlaySaveFormation')) {
    document.getElementById('overlaySaveFormation').style.display="block"
  }
  //console.log($data)

  dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: false })

  let actLanding = JSON.parse(JSON.stringify($data))
  let landingID = $data.id;
  let compareForSave = JSON.parse(sessionStorage.getItem('saveLandingAdmin'))

  //on verifie encore une fois les position de chaque element
  actLanding.paliersScore = JSON.stringify(actLanding.paliersScore) //pour les paliers de score
  compareForSave.paliersScore = JSON.stringify(compareForSave.paliersScore) //pour les paliers de score
  if (actLanding.experiences) {
    actLanding.experiences.forEach((seq, indexSeq) => {
      seq.position = indexSeq;
      seq = sequencesDataRefacto(seq)
    })

    //on verifie encore une fois les position de chaque element
    compareForSave.experiences.forEach((seq, indexSeq) => {
      seq.position = indexSeq;
      seq = sequencesDataRefacto(seq)
    })
  }

  var diffpatcher = jsondiffpatch.create({
    cloneDiffValues: true,
    propertyFilter: function(name, context) {
      if (name !== 'color') {
        return name;
      }
    },
    objectHash: function(obj, index) {
      return obj.id || '$$index:' + index;
    },
    arrays: {
        // default true, detect items moved inside the array (otherwise they will be registered as remove+add)
        detectMove: true,
        // default false, the value of items moved is not included in deltas
        includeValueOnMove: false,
    },
    textDiff: {
        minLength: 99999999999999,
    },
  });
  let delta = diffpatcher.diff(compareForSave, actLanding);
  if (delta) {
    let deltaFormat = jsondiffpatch.formatters.jsonpatch.format(delta)
    let changesArray = []
    let secureDeletesArray = []
    //console.log(deltaFormat)
    deltaFormat.forEach((change) => {
      //console.log(change)
      if (change.op === "replace") {
        let infosUpdate = deltaPathToElementReplaceLanding(actLanding, change.path)
        if (infosUpdate.type) {
          console.log("change "+infosUpdate.type+" with id "+infosUpdate.el.id+", key "+infosUpdate.key+" to "+change.value)
          changesArray.push(async function() {
            return await editLandingElement(
              infosUpdate.type,
              {
                id : infosUpdate.el.id,
                [infosUpdate.key] : change.value
              }
            )
          })
        }
      } if (change.op === "add") {
        let infosAdd = deltaPathToElementAdd(actLanding, change.path)
        change.value.position = infosAdd.position
        delete change.value.id;
        delete change.value.partieID;
        delete change.value.chapterID;
        if (infosAdd.type) {
          console.log("add "+infosAdd.type+" to element with id "+infosAdd.el.id+", with value "+JSON.stringify(change.value))
          if (infosAdd.type === "experiences") {
            changesArray.push(async function() {
              return await addLandingElement(
                infosAdd.type,
                infosAdd.el.id,
                change.value
              )
            })
          }
        }
      } if (change.op === "remove") {
        let infosRemove = deltaPathToElementRemove(compareForSave, change.path)
        if (infosRemove.type) {
          console.log("remove "+infosRemove.type+" with id "+infosRemove.el.id)
          if (infosRemove.type === "experiences") {
            secureDeletesArray.push({
              type : infosRemove.type,
              id : infosRemove.el.id
            })
            changesArray.push(async function() {
              return await removeLandingElement(
                infosRemove.type,
                infosRemove.el.id
              )
            })
          }
        }
      }
    });

    if (changesArray.length > 0) {
      let textConfirm = "Confirmez-vous la suppression des éléments suivants ?\n"
      if (secureDeletesArray.length > 0) {
        for (let i=0; i<secureDeletesArray.length;i++) {
          textConfirm += "- " + secureDeletesArray[i].type + " avec l'ID " + secureDeletesArray[i].id + "\n";
        }
      }
      if (secureDeletesArray.length === 0 || window.confirm(textConfirm) == true) {
        const results = await Promise.all(changesArray.map(f => f()));
        let vals = {}
        if (actLanding.archived === "true") {
          vals.archived = true;
        }
        axios
          .post(GLB_URL_APIS + '/page/admin', vals)
          .then((res) => {
            var newData = res.data
            let thisLanding = newData.find((el) => el.id === landingID)
            thisLanding.forceRefresh = true;
            dispatch({
              type: FETCH_PAGES,
              data: newData,
            })
            dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: false })
          })
          .catch((err) => console.log(err))
      } else {
        document.location.reload()
      }

    }
  } else {
    console.log("no change")
    if (document.getElementById('overlaySaveFormation')) {
      document.getElementById('overlaySaveFormation').style.display="none"
    }
  }

  /*dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: false })
  axios
    .post(GLB_URL_APIS + '/api/admin/page/edit', $data)
    .then((res) => {
      dispatch({
        type: FETCH_PAGES,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))*/
}

export const updateAdminClient = ($data) => (dispatch) => {
    dispatch({
      type: FETCH_ADMINCLIENTS,
      data: $data,
    })
}

export const createColor = ($data) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/color/admin/add', $data)
    .then((res) => {
      dispatch({
        type: FETCH_COLORS,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}

export const updateColor = ($data) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/color/admin/edit', $data)
    .then((res) => {
      dispatch({
        type: FETCH_COLORS,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}

export const createExercice = ($data) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/api/admin/exercice/add', $data)
    .then((res) => {
      dispatch({
        type: FETCH_EXERCICES,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}

export const updateExercice = ($data) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/api/admin/exercice/edit', $data)
    .then((res) => {
      dispatch({
        type: FETCH_EXERCICES,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}
