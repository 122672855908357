import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import { onFormationChange } from '../../../admin/actions/playerActions'
import Button from '../Button'
import EditableField from './EditableField'

class QuestionInputTexte extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAnswer: props.isEditor ? true : props.isAnswer,
      userChoice: props.userChoice,
      response : props.userChoice ? props.userChoice: "",
    }
  }

  componentDidMount() {
  }

  render() {

    if (this.props.isAnswer !== this.state.isAnswer) this.state.isAnswer = this.props.isAnswer

    return (
      <>
        <div className="containerInputTexte answerWrapper" style={{padding: (this.props.image === false || this.props.image === null) ? "0 0 0 45px" : "25px 0 0 0"}}>
          {this.props.answers.type === "input" &&
            <input
              disabled={this.props.isAnswer}
              className={this.props.image ? "inputTexte_input withImage" : "inputTexte_input"}
              maxLength="30"
              type="text"
              value={this.state.response}
              onChange={(e) => {
                let newValue = e.target.value;
                this.setState((prevstate) => ({ response : newValue }));
              }}
            />
          }
          {this.props.answers.type === "textarea" &&
            <textarea
              className={this.props.image ? "inputTexte_textarea withImage" : "inputTexte_textarea"}
              value={this.state.response}
              onChange={(e) => {
                let newValue = e.target.value;
                this.setState((prevstate) => ({ response : newValue }));
              }}
              disabled={this.props.isAnswer}
            />
          }
        </div>
        <div className="btn_wrapper">
          <Button
            disabled={this.state.response.length <= 3}
            key="txt_btn_next"
            isEditor={this.props.isEditor}
            btn={this.props.button || { type: 'primary', text: 'Valider' }}
            btnName={'button'}
            callback={() => {
              this.props.onAnswer(this.state.response)
            }}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  formation: state.playerFormation,
  landing: state.playerLanding,
  index: state.playerIndex,
  playerReduxBug: state.playerReduxBug,
})

export default connect(mapStateToProps, { onFormationChange })(QuestionInputTexte)
