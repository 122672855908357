import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'

import LanguageContext from "../../contexts/LanguageContext"

import './score.css'
class _Score extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }

  }
  componentDidMount() {

  }




  componentDidUpdate() {

  }
  render() {
    return (
      <CSSTransition
        in={this.props.gameIsEnded}
        timeout={{ appear: 0, enter: 0, exit: 400 }}
        unmountOnExit
        appear
        exit
        className={'landing_content scoringLanding fade-top '}
      >
        <div>
          <h1 className="landing_title">{/*this.props.landing.titre*/}Merci pour votre participation !</h1>
          <div className="landing_score_wrapper">
          </div>
          <div className="landing_score_sharing">
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default _Score
_Score.contextType = LanguageContext
