import EditableField from '../../../components/player/helpers/EditableField.js'
import Dropdown from '../../../components/player/helpers/Dropdown.js'
import Toggle from '../../../components/player/helpers/Toggle.js'

import ImageSetting from '../../../admin/components/formation/player/ImageSetting.js'
import React, { Component } from 'react'
import updateFormation from '../../../utils/updateFormation.js'

class InputTexteDropdown  extends Component {
  constructor(props){
    super(props)
    this.core = props.core
  }



  render() {
    return (
      <Dropdown side='left'>
        <div className="dropdown_item">
          <div style={{width:'100%'}}>
            <h4 style={{marginBottom:'5px', fontSize:'10px'}}>Questions</h4>
            <Toggle
              isLanding={this.core.props.isLanding}
              changeFormation={false}
              type="answers"
              check={this.core.props.format.answers.type === "textarea"}
              label="TextArea"
              callback={(value) => {
                let newAnswers = JSON.parse(JSON.stringify(this.core.props.format.answers))
                if (value === true) { newAnswers.type = 'textarea' }
                else if (value === false) { newAnswers.type = 'input' }
                updateFormation('answers', newAnswers, this.core.props.isLanding)
              }}
            />
          </div>
        </div>
        <ImageSetting
          isLanding={this.core.props.isLanding}
          image={this.core.props.format.image}
          onReRender={this.core.onReRender}
        />
      </Dropdown>
    )
  }
}

export default InputTexteDropdown
