import { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  Toolbar
} from '@material-ui/core';

import blackLogo from '../../img/logo_RisingUp_black.png';
import thelabLogo from '../../img/logo_theLab.png';

const LayoutNavbar = ({ ...rest }) => {

  return (
    <AppBar
      elevation={0}
      sx={{background:"#FFFFFF"}}
      {...rest}
    >
      <Toolbar sx={{background:"#FFFFFF", height:'90px'}}>
        <RouterLink to="/">
          <img style={{height:'60px'}} src={blackLogo} alt="blackLogo" />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{display:"flex", gap:"30px", color:"black"}}>
          <img style={{width:'158px'}} src={thelabLogo} alt="thelabLogo" />
        </Box>
      </Toolbar>
    </AppBar>
  );
};


export default LayoutNavbar;
