import React, { Component, useState } from 'react'

import { connect } from 'react-redux'

import { onFormationChange } from '../../../admin/actions/playerActions'

import Toggle from 'react-toggle'
import 'react-toggle/style.css'

class Toogle extends Component {
  constructor(props) {
    super(props)
    this.state = { check: typeof(props.check) === "number" ? props.check === 0 ? false : true : props.check}
  }

  handleToggle = (e) => {
    this.setState({ check: e.target.checked })
    if (this.props.changeFormation !== false) {
      this.props.onFormationChange(
        this.props.type,
        e.target.checked,
        this.props.index,
        this.props.isLanding ? this.props.landing : this.props.formation,
        this.props.isLanding ? true : false
      )
    }
    if (this.props.callback) this.props.callback(e.target.checked)
  }

  render() {
    return (
      <>
        <Toggle
          id={this.props.type}
          checked={this.state.check}
          icons={false}
          onChange={this.handleToggle}
        />
        <label htmlFor="is_chapter">{this.props.label}</label>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  formation: state.playerFormation,
  landing: state.playerLanding,
  index: state.playerIndex,
})

export default connect(mapStateToProps, { onFormationChange })(Toogle)
