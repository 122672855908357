import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Onboarding from '../components/dashboard/Onboarding'

const PrivateRouteAdmin = ({ component: Component, auth, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated === true && user.isAdmin === true ? (
          <Component {...props} />
        ) : (
          <>
            {user.type === "student" &&
              <Redirect to="/dashboard" />
            }

            {user.type === "adminclient" &&
              <Redirect to="/admindashboard" />
            }
          </>
        )
      }
    />
  )
}
PrivateRouteAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
})
export default connect(mapStateToProps)(PrivateRouteAdmin)
