import axios from 'axios'
import { getPointsByQuizz } from '../utils/PlayerFn.js'

import { FETCH_PAGE, SET_COLOR, SET_PAGE_RESULT, SET_PAGE_SCORE, SET_PAGE_USER,GLB_URL_APIS } from './types'
let jsonType = {
  headers: {
    'Content-Type': 'application/json',
  },
}

export const getLandingPage = ($slug) => (dispatch) => {
  axios
    .post(GLB_URL_APIS + '/page', { slug: $slug })
    .then((res) => {
      dispatch({
        type: SET_COLOR,
        data: res.data.color,
      })
      dispatch({
        type: FETCH_PAGE,
        data: res.data,
      })
    })
    .catch((err) => console.log(err))
}

export const setLandingResult = (result) => (dispatch) => {
  dispatch({
    type: SET_PAGE_RESULT,
    data: result,
  })
}

export const setLandingScore = ($id, $slug, $landingUser, result, callback) => (dispatch) => {
  dispatch({
    type: SET_PAGE_RESULT,
    data: result,
  })

  /*var score = { totalQuestions: 0, totalGoodAnswer: 0 }
  for (var format of result) {
    if ((format.type === 'QuestionnaireV2' || format.type === 'Quizz') && format.userIsValid !== 0) {
      score.totalQuestions += 1
      if (format.userIsValid === true) score.totalGoodAnswer += 1
    }
  }
  if (score.totalQuestions == 0) {
    score.pourcent = null
  } else {
    score.pourcent = Math.round((score.totalGoodAnswer / score.totalQuestions) * 100)
  }*/

  var score = { points: 0, max: 0, score: 0 }

  const manageFormat = (format) => {
    if (['Quizz', 'QuestionnaireV2'].includes(format.type )) {
      const [points, max] = getPointsByQuizz(format)
      //const makeResult = (o) => ({ ...o, max: o.max + max, points: o.points + points, score: o.max > 0 ? Math.round((o.points / o.max) * 100) : 100 })
      const makeResult = (c, points, max) => {
        let newPoints = c.points+points
        let newMax = c.max+max
        return {
          points : newPoints,
          max : newMax,
          score : Math.round((newPoints / newMax) * 100)
        }
      }
      score = makeResult(score, points, max)
    }
  }

  for (var format of result) {
    manageFormat(format)
  }


  dispatch({
    type: SET_PAGE_SCORE,
    data: score,
  })


  callback()

  let landingUserID = sessionStorage.getItem('saveLandingUserID')

  result.forEach((seq, indexSeq) => {
    seq = sequencesDataRefacto(seq)
  })

  if (landingUserID !== null || landingUserID !== 'null') {
    let params = {
      slug: $slug,
      landingPageID : $id,
      userID : landingUserID,
      email: $landingUser.email,
      firstname: $landingUser.firstname,
      result: result,
      score: score.score,
    }
    axios
      .post(GLB_URL_APIS + '/page/saveResult', params)
      .then((res) => {
        sessionStorage.setItem('saveLandingUserID', null);
      })
      .catch((err) => console.log(err))
    
  }
}

const sequencesDataRefacto = (seq) => {
  if (seq.userChoice && seq.userChoice !== null) {
    try {
      seq.userChoice = JSON.stringify(seq.userChoice)
    } catch(e) {
      seq.userChoice = seq.userChoice
    }
  }

  return seq
}

export const setLandingUser = ($id, $slug, $email, $firstname) => (dispatch) => {
  dispatch({
    type: SET_PAGE_USER,
    data: {
      email: $email,
      firstname: $firstname,
    },
  })
  axios
    .post(GLB_URL_APIS + '/page/createUser', {
      slug: $slug,
      email: $email,
      firstname: $firstname,
      landingPageID : $id,
    })
    .then((res) => {
      if (res.data && res.data.id) {
        sessionStorage.setItem('saveLandingUserID', res.data.id);
      }
    })
    .catch((err) => console.log(err))
}
