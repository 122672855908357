import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Onboarding from '../components/dashboard/Onboarding'

const PrivateRoute = ({ component: Component, auth, user, plan, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          auth.isAuthenticated === true ? (
            <>
              {user.type === "student" ? (
                <>
                  {user.isOnboarded ? (
                    <>
                      {(() => {
                        if (user.isAdmin === true) {
                          plan[0].isDone = true
                        }
                        if (plan[0].isDone === false && props.location.pathname !== "/icebreaker") {
                          return (
                            <Redirect to="/icebreaker" />
                          )
                        } else if (plan[0].isDone === false && props.location.pathname === "/icebreaker") {
                          return (
                            <Component {...props} />
                          )
                        } else if (plan[0].isDone === true && props.location.pathname === "/icebreaker") {
                          return (
                            <Redirect to="/dashboard" />
                          )
                        } else if (plan[0].isDone === true && props.location.pathname !== "/icebreaker") {
                          return (
                            <Component {...props} />
                          )
                        }
                      })()}
                    </>
                  ) : (
                    <Onboarding {...props} />
                  )}
                </>
              ) : (
                <Redirect to="/admindashboard" />
              )}
            </>
          ) : (
            <Redirect to="/login" />
          )
        )
      }}
    />
  )
}
PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  plan: PropTypes.array.isRequired,
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  plan : state.plan,
})
export default connect(mapStateToProps)(PrivateRoute)
