import React from 'react'

const backGround = (props) => {
  var style = {}
  // if(props.quoteIsEnded){
  style = {
    background:
      'linear-gradient(-45deg,' +
      props.color.grayLinear.first +
      ',' +
      props.color.grayLinear.last +
      ')',
  }
  // }else{
  //   style ={
  //     background:"linear-gradient(-45deg, rgb(251, 18, 190),  rgb(251, 18, 190),  rgb(96, 157, 229),  rgb(51, 249, 240),  rgb(176, 220, 122),  rgb(253, 216, 106),  rgb(253, 216, 106))",
  //     backgroundSize:"1000% 1000%",
  //     animation: "gradient 25s ease infinite",
  //   }
  // }
  var vagues = ''
  if (props.quoteIsEnded) {
    vagues = (
      <g>
        <path
          opacity={0.184}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M497 811.867s24-285.788 301-183.606c277 102.183 642-193.954 642-193.954V796H543l-46 15.867z"
          fill="#070441"
        />
        <path
          opacity={0.075}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 813.56s36.013-285.789 451.66-183.606c168.333 41.383 371.52 147.442 572.34 90.828C1178.76 684.203 1440 436 1440 436v361.693H94.024L25 813.56z"
          fill="#040541"
        />
      </g>
    )
  }
  return (
    <svg
      style={style}
      className="backgroundSVGAnimation"
      viewBox="0 0 1440 796"
      width="100%"
      height="100%"
      preserveAspectRatio="xMaxYMax slice"
      fill="none"
    >
      {vagues}

      <text
        x="1400"
        y="237"
        opacity={0.05}
        fill="#fff"
        fontFamily="Circular"
        textAnchor="end"
        fontSize="99"
      >
        {props.introIsEnded ? props.title : ''}
      </text>
    </svg>
  )
}

export default backGround
