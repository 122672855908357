import React, { useState, createContext, useEffect, useContext} from "react"
import { connect } from 'react-redux'

export const AdminGroupesContext = createContext()

const AdminGroupesProvider = ({ auth, user, adminGroupes, children }) => {

  const [groupes, setGroupes] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [actGroupe, setActGroupe] = useState(null)
  const [actGroupeID, setActGroupeID] = useState(null)
  const [actPage, setActPage] = useState(null)

  let defaultPage = "home"
  const getGroupeAndPage = (url) => {
    url = url.replace('/admindashboard', '')
    url = url.replace('/admindashboard/', '')
    let split = url.split('/')
    split = split.filter((el) => el !== '')

    if (split.length === 0) {
      return { id : null, page : defaultPage }
    } else if (split.length === 1) {
      return { id : parseInt(split[0]), page : defaultPage }
    } else if (split.length === 2) {
      if ((split[1] === defaultPage) || (split[1] === "suivi") || (split[1] === "mapping") || (split[1] === "certification")) {
        return { id : parseInt(split[0]), page : split[1] }
      } else {
        return { id : parseInt(split[0]), page : defaultPage }
      }
    } else {
      return { id : null, page : defaultPage }
    }
  }

  useEffect(() => {
    let include = window.location.pathname.includes("/admindashboard")
    if (user.type === "adminclient") {
      if (loaded === false && auth.formationLoaded === true) {

        if (adminGroupes.length > 0) {
          let groupeAndPage = getGroupeAndPage(window.location.pathname)
          let finalID = null
          let finalPage = groupeAndPage.page
          if (groupeAndPage.id === null) { //on prend le premier groupe
            finalID = adminGroupes[0].groupeID
          } else {//on check si l'ID existe, sinon on prend le premier groupe
            let find = adminGroupes.find((el) => el.groupeID === groupeAndPage.id)
            if (find) {
              finalID = find.groupeID
            } else {
              finalID = adminGroupes[0].groupeID
            }
          }
          setGroupes(adminGroupes)
          setActGroupeID(finalID)
          setActGroupe(adminGroupes.find((el) => el.groupeID === finalID))

          if (finalPage === "suivi" && user.plan.suivi !== true) { finalPage = defaultPage }
          if (finalPage === "mapping" && user.plan.mapping !== true) { finalPage = defaultPage }
          if (finalPage === "certification" && user.plan.certif !== true) { finalPage = defaultPage }
          setActPage(finalPage)

          setLoaded(true)

          if (include) {
            let finalURL = window.location.origin+'/admindashboard/'+finalID+'/'+finalPage
            window.history.replaceState(null, null, finalURL)
          }

        } else {
          console.log("no groupes")
          let finalURL = window.location.origin+'/admindashboard/'
          window.history.replaceState(null, null, finalURL)
          setLoaded(true)
        }
      }
    }
  }, [auth.formationLoaded, adminGroupes, window.location.pathname])

  const updateActGroupe = (id, page) => {
    let groupeAndPage = getGroupeAndPage(window.location.pathname)
    let find = groupes.find((el) => el.groupeID === id)
    if (find) {
      groupeAndPage.id = id
      setActGroupeID(id)
      setActGroupe(find)
    } else {
      groupeAndPage.id = groupes[0].groupeID
      setActGroupeID(groupes[0].groupeID)
      setActGroupe(groupes[0])
    }

    if (page) {
      groupeAndPage.page = page
      setActPage(groupeAndPage.page)
    }

    let finalURL = window.location.origin+'/admindashboard/'+groupeAndPage.id+'/'+groupeAndPage.page
    window.history.replaceState(null, null, finalURL)
  }

  return (
    <AdminGroupesContext.Provider
      value={{
        loaded : loaded,
        groupes : groupes,
        actGroupeID : actGroupeID,
        actGroupe : actGroupe,
        actPage : actPage,
        updateActGroupe : updateActGroupe,
      }}
    >
      {children}
    </AdminGroupesContext.Provider>
  )
}

export default AdminGroupesContext

const mapStateToProps = (state) => {
  return {
    auth : state.auth,
    user : state.user,
    adminGroupes : state.adminGroupes,
  }
}

const AdminGroupesProvider2 = connect(
  mapStateToProps
)(AdminGroupesProvider)

export { AdminGroupesProvider2 }
