const playerReduxBugReducer = (state = { isToggle: false }, action) => {
  switch (action.type) {
    case 'ADMIN_PLAYER_BUG':
      return {
        ...state,
        isToggle: !state.isToggle,
      }
    default:
      return state
  }
}

export default playerReduxBugReducer
