import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { StyledButton } from "./Layout"

const AlertDialog = ({open, title, subTitle, closeText, validText, handleClose, handleValid}) => {

  return (
    <div>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "30px",
            padding: "10px",
          },
        }}
      >
        <DialogTitle sx={{fontSize: "25px", fontWeight:"bold"}}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{color:"black", fontSize:"14px"}}>
            {subTitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent:"center", gap:"15px"}}>
          <StyledButton
            softbg="true"
            sx={{width:"130px"}}
            variant="contained"
            onClick={handleClose}
          >
            {closeText}
          </StyledButton>
          <StyledButton
            sx={{width:"130px"}}
            variant="contained"
            onClick={handleValid}
          >
            {validText}
          </StyledButton>

        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog
