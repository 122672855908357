import { SET_CURRENT_USER, USER_FORMATION_LOADED } from '../actions/types'
const isEmpty = require('is-empty')
const initialState = {
  isAuthenticated: false,
  user: {},
  formationLoaded: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      }
    case USER_FORMATION_LOADED:
      return {
        ...state,
        formationLoaded: true,
      }

    default:
      return state
  }
}
