export function getPointsByQuizz(quizz) {

  var maxPoints = 0
  for (var res of quizz.responses) {
    var pts = parseInt(res.points)
    if (quizz.multi && pts > 0) maxPoints += pts
    if (!quizz.multi && pts > maxPoints) maxPoints = pts
  }

  if (quizz.userChoice === null || quizz.userChoice === undefined) {
    return [0, maxPoints]
  }
  var userPoints = 0
  if (typeof(quizz.userChoice) === "number") {
    let res = quizz.responses[quizz.userChoice]
    if (res && res.points) userPoints += parseInt(res.points)
  } else {
    for (var choice of quizz.userChoice) {
      let res = quizz.responses[choice]
      if (res && res.points) userPoints += parseInt(res.points)
    }
  }

  return [userPoints, maxPoints]
}
