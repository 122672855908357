const colorsReducer = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_COLORS':
      return action.data
    default:
      return state
  }
}

export default colorsReducer
