import React, { Component } from 'react'

class Format extends Component {
  constructor(props) {
    super(props)

    this.state = {
      forceRerender: false,
    }
  }

  manageResponse = () => {
    if (!this.props.format.isDone) {
      this.props.onDataChange([{ key: 'isDone', value: true }])
    }
    this.props.manageSlide(1)
  }

  onReRender = () => {
    this.setState({ forceRerender: !this.state.forceRerender })
  }
}

export default Format
