import React from 'react'

const MaskTest = (props) => {
  var style = {}
  if (props.image.type == 'cover') style = { backgroundImage: 'url(' + props.image.link + ')' }

  let className = "format_mask_content mask_inline_image"
  if (props.noMobile === true) {
    className += " noDisplayForMobile"
  }

  return (
    <div
      className={props.image.type == 'cover' ? 'format_mask maskCover' : 'format_mask'}
      style={style}
    >
      {props.image.type == 'contain' ? (
        <>
          <img src="/img/mask-clear.svg" />
          <div className={className}>
            {' '}
            <img src={props.image.link} />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default MaskTest
