import React, { Component } from 'react'
import EditableField from './helpers/EditableField'

class Button extends Component {
  constructor(props) {
    super(props)

  }

  onEditorChange = (text) => {
    var data = this.props.btn
    data.text = text
    if (this.props.onButtonChange) {
      return this.props.onButtonChange(data)
    }

    return { data: data, index: false }
  }

  render() {
    if (this.props.isEditor) {
      return (
        <button className={'btn_' + this.props.btn.type} type={this.props.isSubmit ? 'submit' : ''}>
          <EditableField
            isLanding={this.props.isLanding}
            isEditor={true}
            type={this.props.btnName ? this.props.btnName : 'button'}
            field={this.props.btn.text}
            tag="span"
            onInputChange={this.onEditorChange}
          />
        </button>
      )
    } else {
      return (
        <button
          disabled={this.props.disabled}
          className={'btn_' + this.props.btn.type}
          onClick={() => {
            this.props.callback()
          }}
        >
          {this.props.btn.text}
        </button>
      )
    }
  }
}

export default Button
