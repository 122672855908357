import React, { useEffect, useContext, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { initData } from '../../actions/formationActions'

import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

import LanguageContext from "../../contexts/LanguageContext"

import { Box, FormControl, Select, MenuItem, } from '@material-ui/core'

import Layout, { StyledTextField, StyledButton } from "../mainLayout/Layout"

import frenchLanguage from '../../img/frenchLanguage.png';
import englishLanguage from '../../img/englishLanguage.png';
import { languages } from "./Register"


const ErrorLogin = (props) => {
  const languageContext = useContext(LanguageContext);

  const languageRef = useRef(null)

  useEffect(() => {
    if (props.auth.isAuthenticated === true) {
      props.history.push("/dashboard");
    }
  }, [props.auth])

  return (
    <Layout componentsDown={
      <Box onClick={() => { props.history.push("/login") }} sx={{cursor:"pointer", fontSize:"14px", display:"flex", gap:"5px"}}>
        <Box sx={{color:"#626262"}}>
          {languageContext.values.register.alreadyAccount[languageContext.language]}
          &nbsp;<u style={{color:"black"}}>{languageContext.values.global.click[languageContext.language]}</u>
        </Box>
      </Box>
    }>
      <Box sx={{marginBottom:"30px", fontSize: "25px", fontWeight:"bold",}}>
        {languageContext.values.errorLogin.title[languageContext.language]}
      </Box>
      <Box
        sx={{
          marginTop:"10px",
          marginBottom:"25px",
          fontSize:"14px",
          color:"#626262"
        }}
      >
        {languageContext.values.errorLogin.texte[languageContext.language]}
      </Box>

      <StyledButton
        fullWidth
        variant="contained"
        sx={{marginTop:"20px"}}
        onClick={() => { props.history.push("/") }}
      >
        {languageContext.values.errorLogin.button[languageContext.language]}
      </StyledButton>
    </Layout>
  )
}



ErrorLogin.propTypes = {
  auth: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  auth: state.auth,
})
export default connect(mapStateToProps, { initData })(ErrorLogin)
