import React from "react"

const defaultState = {
  notifs: [],
  toDisplay : "",
  display: false,
  add: () => {},
  remove: () => {},
  removeAll: () => {},
}

const PopupNotifContext = React.createContext(defaultState)

class PopupNotifProvider extends React.Component {

  state = {
    notifs: [],
    toDisplay : "",
    display: false,
  }

  updateGlobal = () => {
    let display = this.state.display
    let toDisplay = this.state.toDisplay
    let notifs = this.state.notifs
    if (notifs.length > 0) {
      //on a des notifs, on regarde le type de la dernière
      let lastItem = notifs[notifs.length - 1]
      toDisplay = lastItem.text

      display = true
    } else {
      display = false
    }

    this.setState({display: display, toDisplay : toDisplay})
  }

  add = (vals) => {
    if (vals.removeAfter) {
      //on supprime cette upload/notif après un certain temps automatiquement
      vals.removeFct = setTimeout(
        function (key) {
          this.remove({ key: key })
        }.bind(this, vals.key),
        vals.removeAfter
      )
    }

    this.setState(
      {
        notifs: [...this.state.notifs, vals],
      },
      () => {
        this.updateGlobal()
      }
    )
  }

  remove = (vals) => {
    var notifs = this.state.notifs.filter((item) => item.key !== vals.key)
    this.setState(
      {
        notifs: notifs,
      },
      () => {
        this.updateGlobal()
      }
    )
  }

  removeAll = () => {
    this.setState(
      {
        notifs: [],
      },
      () => {
        this.updateGlobal()
      }
    )
  }


  componentDidMount() {}

  render() {
    const { children } = this.props
    return (
      <>
        <PopupNotifContext.Provider
          value={{
            notifs: this.state.notifs,
            display: this.state.display,
            toDisplay : this.state.toDisplay,
            add: this.add,
            remove: this.remove,
            removeAll: this.removeAll,
          }}
        >
          {children}
        </PopupNotifContext.Provider>
      </>
    )
  }
}

export default PopupNotifContext

export { PopupNotifProvider }
