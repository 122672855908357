import React, { useEffect, useContext, useState } from 'react'

import LayoutNavbar from './LayoutNavbar';

import { Box, Hidden, TextField, Button } from '@material-ui/core'
import styled from "styled-components";

import "./Layout.css"

const Layout = (props) => {

  return (
    <Box>
      <LayoutNavbar />
      <Box sx={{ position:"absolute", top:"100px", width:'100%', height:'calc(100% - 100px)', overflowY: 'auto'}}>
        <Box
          sx={{
            width:"100%",
            height:props.noBottom === true ? "100%" : "calc(100% - 80px)",
            display:'flex',
            justifyContent:"center",
            alignItems:"center"
          }}
        >
          <Box sx={{
            textAlign:"center",
            width:props.fullWidth === true ? "100%" : "400px",
            maxWidth : props.fullWidth === true ? "100%" : "400px",
            margin : "0px 30px",
            display:'flex',
            flexDirection:"column",
            alignItems:"center",
          }}>
            {props.children}
          </Box>
        </Box>
        {props.noBottom !== true &&
          <Box
            sx={{height:"80px", width:"100%", position:"absolute", bottom:0, display:'flex', justifyContent:"center", alignItems:"center"}}
          >
            <Box sx={{ margin : "0px 45px", textAlign:"center", width:"100%", display:'flex', justifyContent:"center", alignItems:"center"}}>
              {props.componentsDown}
            </Box>
          </Box>
        }
      </Box>
    </Box>
  )
}

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    height: 40px;
    text-transform : none;
  }
  &.MuiButton-root.Mui-disabled {
    background : rgba(0, 0, 0, 0.2);
  }
  &.MuiButton-root:not(.Mui-disabled) {
    background : ${props => props.softbg === "true" ? "#819DB9" : "#0C457C"};
  }
`;

export const StyledTextField = styled(TextField)`
  background: #EDEDED;
  font-family : "Circular";
  margin-bottom: 15px;
  & label.Mui-focused {
    color: #0C457C;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #BEBEBE;
      border-radius: 6px;
    }
    &:hover fieldset {
      border-color: #BEBEBE;
    }
    &.Mui-focused fieldset {
      border-color: #0C457C;
    }
  }
`;

export default Layout
