import React, { Component } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";

class DonutV3 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value : 0
    }

    setTimeout(() => {
      this.setState({value : this.props.score})
    }, this.props.totalAnim*1000)
  }

  render() {
    const width = this.props.width ?? 79


    return (
      <div className="feedback_donut" style={{paddingRight:'20px'}}>
        <div style={{width : width}}>
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={this.state.value}
            duration={3}
            easingFunction={easeQuadInOut}
          >
            {value => {
              const roundedValue = Math.round(value);
              return (
                <CircularProgressbar
                  value={value}
                  background
                  strokeWidth={3}
                  text={`${roundedValue}%`}
                  styles={buildStyles({
                    pathTransition: "none",
                    pathColor: "white",
                    textColor : "white",
                    trailColor : "transparent",
                    textSize: '30px',
                    backgroundColor: 'rgba(255, 255, 255, 0.13)'
                  })}
                />
              );
            }}
          </AnimatedProgressProvider>
        </div>
      </div>
    )
  }
}

export default DonutV3
