import React, { useContext, useEffect } from "react"
import styled from "styled-components"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Hidden from "@material-ui/core/Hidden"
import {
  X as XIcon,
} from 'react-feather';

import PopupNotifContext from "./contexts/PopupNotifContext"

const ContainerNoPadding = styled(({ ...otherProps }) => (
  <Container {...otherProps} />
))`
  height : 100%;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
`

const DivStyled = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 1px solid white;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
`


const PopupNotif = () => {

  const popupNotifContext = useContext(PopupNotifContext)

  return (
      <>
        <Box sx={{
          bgcolor:'black',
          position:"fixed",
          width:"100%",
          height:["60px", "60px", "67px"],
          bottom : popupNotifContext.display ? '0px' : '-67px',
          left:0,
          zIndex : 999999999,
          transition: "all .5s ease-in-out",
        }}>
          <ContainerNoPadding maxWidth="lg">
            <Box
              display="flex"
              sx={{height:'100%'}}
            >
              <Box sx={{
                width:["10px", "10px", "50px"],
                height:'100%'
              }}>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  height:'100%',
                  flex:1,
                  cursor:'default',
                  overflow:'hidden',
                  textAlign:'center',
                }}
              >
                <Box
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow:'hidden',
                    color:'white',
                    fontWeight:'bold',
                    fontSize:['13px', '15px', '15px', '17px']
                  }}
                  dangerouslySetInnerHTML={{__html: popupNotifContext.toDisplay}}>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width:'50px',
                  height:'100%'
                }}
              >
                <DivStyled
                  onClick={() => {popupNotifContext.removeAll()}}
                >
                  <XIcon
                    stroke="white"
                    style={{
                      cursor:"pointer"
                    }}
                    size="16"
                  />
                </DivStyled>
              </Box>
            </Box>
          </ContainerNoPadding>

        </Box>
      </>
  )
}

export default PopupNotif
