import React from 'react'

const MaskQuizz = (props) => {
  return (
    <div className="format_mask maskCover">
      <img src="/img/mask-clear.svg" />
      <div className="format_mask_content">{props.children}</div>
    </div>
  )
}

export default MaskQuizz
