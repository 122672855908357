import React, { Component } from 'react'

import Format from './Format.js'
import Button from '../Button'
import MaskText from '../helpers/MaskText.js'

import EditableField from '../helpers/EditableField.js'
import EditableArea from '../helpers/EditableArea.js'
import Dropdown from '../helpers/Dropdown.js'
class FormatTitre extends Format {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="format_titre formatBox">
        <div className="format_mask">
          <img className="title_bkgr" src="/img/mask-titre.svg" />
          <div className="title_icon_wrapper">
            <img className="title_icon" src={this.props.format.icon} />
          </div>
        </div>
        <div className="format_wrapper">
          <div className="main_wrapper">
            <EditableField
              isLanding={this.props.isLanding}
              tag="h1"
              field={this.props.format.title}
              isEditor={this.props.isEditor}
              type="title"
            />
            {this.props.format.subTitle && (
              <EditableField
                isLanding={this.props.isLanding}
                tag="h3"
                field={this.props.format.subTitle}
                isEditor={this.props.isEditor}
                type="subTitle"
              />
            )}
            {this.props.format.texte && (
              <EditableArea
                isLanding={this.props.isLanding}
                tag="div"
                field={this.props.format.texte}
                isEditor={this.props.isEditor}
                type="texte"
              />
            )}
          </div>

          <div className="btn_wrapper">
            <Button
              key="txt_btn_next"
              isEditor={this.props.isEditor}
              isLanding={this.props.isLanding}
              btn={this.props.format.button}
              callback={() => {
                this.manageResponse()
              }}
            />
          </div>
        </div>
        {this.props.isEditor && (
          <Dropdown>
            <div className="dropdown_item dropdown_field">
              <h5>Url Icon:</h5>
              <EditableField
                isLanding={this.props.isLanding}
                tag="p"
                field={this.props.format.icon}
                isEditor={true}
                type="icon"
              />
            </div>
          </Dropdown>
        )}
      </div>
    )
  }
}

export default FormatTitre
