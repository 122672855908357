import React, { Component } from 'react'

import Format from './Format.js'
import Button from '../Button'
import MaskText from '../helpers/MaskText.js'

import EditableField from '../helpers/EditableField.js'
import EditableArea from '../helpers/EditableArea.js'
import Dropdown from '../helpers/Dropdown.js'
import ImageSetting from '../../../admin/components/formation/player/ImageSetting.js'
class FormatTexte extends Format {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: window.innerWidth <= 600,
    }
  }

  updateIsMobile() {
    this.setState(() => ({ isMobile: window.innerWidth <= 600 }))
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile.bind(this))
  }

  render() {
    return (
      <div className="format_text formatBox">
        <div className="format_wrapper">
          <div className="main_wrapper">
            {this.props.isEditor || this.props.format.exerciceTitle ? (
              <EditableField
                isLanding={this.props.isLanding}
                tag="h1"
                classTag="xs"
                field={this.props.format.exerciceTitle}
                isEditor={this.props.isEditor}
                type="exerciceTitle"
              />
            ) : (
              ''
            )}
            <EditableField
              isLanding={this.props.isLanding}
              tag="h2"
              field={this.props.format.title}
              isEditor={this.props.isEditor}
              type="title"
            />
            <EditableArea
              isLanding={this.props.isLanding}
              tag="div"
              field={this.props.format.text}
              isEditor={this.props.isEditor}
              type="text"
            />
          </div>
          {this.props.format.image && this.state.isMobile ? <MaskText image={this.props.format.image} /> : ''}
          <div className="btn_wrapper">
            <Button
              key="txt_btn_next"
              isLanding={this.props.isLanding}
              isEditor={this.props.isEditor}
              btn={this.props.format.button}
              callback={() => {
                this.manageResponse()
              }}
            />
          </div>
        </div>
        {this.props.format.image && !this.state.isMobile ? <MaskText image={this.props.format.image} /> : ''}
        {this.props.isEditor && (
          <Dropdown>
            <ImageSetting
              isLanding={this.props.isLanding}
              image={this.props.format.image}
              onReRender={this.onReRender}
            />
          </Dropdown>
        )}
      </div>
    )
  }
}

export default FormatTexte
