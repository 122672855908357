const playerIndexReducer = (state = { iChap: 0, iPart: 0, iSeq: 0 }, action) => {
  switch (action.type) {
    case 'ADMIN_PLAYER_INDEX':
      return action.data
    default:
      return state
  }
}

export default playerIndexReducer
