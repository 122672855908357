import React from "react"
import socketio from "socket.io-client";
import { GLB_URL_APIS } from '../actions/types'
import axios from 'axios'


export let socket = socketio.connect(GLB_URL_APIS,
  {withCredentials: true}
);
export const renewSocket = () => {
  socket.disconnect()

  socket = socketio.connect(GLB_URL_APIS,
    {withCredentials: true}
  )
}
export const SocketContext = React.createContext();
