import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Onboarding from '../components/dashboard/Onboarding'

const PrivateRouteClientAdmin = ({ component: Component, auth, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          auth.isAuthenticated === true ? (
            <>
              {user.type === "adminclient" ? (
                <>
                  {user.isOnboarded ? (
                    <Component {...props} />
                  ) : (
                    <Onboarding {...props} />
                  )}
                </>
              ) : (
                <Redirect to="/dashboard" />
              )}
            </>
          ) : (
            <Redirect to="/login" />
          )
        )
      }}
    />
  )
}
PrivateRouteClientAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
})
export default connect(mapStateToProps)(PrivateRouteClientAdmin)
