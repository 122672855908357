import React, { useEffect, useContext, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { onBoard, logoutUser } from '../../actions/authActions'

import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'

import LanguageContext from "../../contexts/LanguageContext"

import { Box, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core'

import Layout, { StyledTextField, StyledButton } from "../mainLayout/Layout"

Moment.locale('fr')
momentLocalizer()

const Onboarding = (props, context) => {

  const languageContext = useContext(LanguageContext)

  const sexeRef = useRef(null)

  const [sexe, setSexe] = useState("")
  const [age, setAge] = useState("")
  const [poste, setPoste] = useState("")

  let sexes = [
    {
      label: languageContext.values.onBoarding.q2Rep1[languageContext.language],
      value: 0,
    },
    {
      label: languageContext.values.onBoarding.q2Rep2[languageContext.language],
      value: 1,
    },
  ];

  const [submittingRegister, setSubmittingRegister] = useState(false)
  const [registerError, setRegisterError] = useState({})

  const handleOnboarding = () => {
    setSubmittingRegister(true)

    props.onBoard({ sexe: sexe, age : age, poste : poste, departement : 99999 }, props.user)
  }


  return (
    <Layout componentsDown={
      <Box sx={{width:"100%", display:'flex', flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <Box onClick={() => { props.logoutUser() }} sx={{cursor:"pointer", fontSize:"14px", display:"flex", gap:"5px"}}>
          <Box sx={{color:"#626262"}}>
            &nbsp;<u style={{color:"black"}}>{languageContext.values.dashboardMenu.logOut[languageContext.language]}</u>
          </Box>
        </Box>
        <Box sx={{width:"100%", maxWidth : "600px", marginTop:"20px", marginBottom:"15px", display:'flex', gap:["20px", "35px", "35px", "35px"]}}>
          <Box sx={{flex:1, height:'6px', borderRadius:"3px", background: "#0C457C"}}>
          </Box>
          <Box sx={{flex:1, height:'6px', borderRadius:"3px", background: "#0C457C"}}>
          </Box>
          <Box sx={{flex:1, height:'6px', borderRadius:"3px", background: "#0C457C"}}>
          </Box>
          {props.user.type === "student" &&
            <Box sx={{flex:1, height:'6px', borderRadius:"3px", background: "#0C457C"}}>
            </Box>
          }
        </Box>
      </Box>
    }>
      <>
        <Box sx={{marginBottom:"30px", fontSize: "25px", fontWeight:"bold",}}>
          {languageContext.values.register.infos.title[languageContext.language]}
        </Box>
        {/*<Box sx={{marginBottom:"30px", fontSize: "14px", color:"#626262",}}>
          {languageContext.values.register.infos.subTitle[languageContext.language]}
        </Box>*/}
        <FormControl fullWidth>
          <Select
            ref={sexeRef}
            value={sexe}
            name="sexe"
            displayEmpty
            onChange={(e) => { setSexe(e.target.value) }}
            onClose={() => {
              sexeRef.current.classList.remove('Mui-focused');
              sexeRef.current.previousSibling?.classList.remove('Mui-focused');
             }}
            onOpen={() => {
              sexeRef.current.classList?.add('Mui-focused');
              sexeRef.current.previousSibling?.classList.add('Mui-focused');
            }}
            renderValue={(selected, value) => {
              if (selected === "") {
                return <font style={{color:'rgba(0,0,0,0.65)',}}>{languageContext.values.onBoarding.sexe[languageContext.language]}</font>;
              }

              return sexes[selected].label
            }}
            sx={{
              color: 'black',
              background:"#EDEDED",
              marginBottom:"15px",
              '& .MuiSelect-select': {
                padding:"15px",
                display:'flex',
                alignItems:"center",
                gap:"15px",
                border:""
              },
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#BEBEBE',
                  borderRadius: '6px',
                },
                '&:hover fieldset': {
                  borderColor: '#BEBEBE',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#0C457C',
                },
              },
            }}
            MenuProps={{
              sx: {
              '& .MuiMenu-paper': {
                //marginTop:"5px",
                backgroundColor: '#EDEDED',
                borderColor: '#BEBEBE',
                borderRadius: '6px',
              },
              '& .MuiMenuItem-root': {
                  padding:"13px",
              },
              '& .MuiMenuItem-root:hover': {
                  backgroundColor: '#EDEDED',
                  color : "#0C457C",
              },
              '& .Mui-selected': {
                  backgroundColor: '#EDEDED',
              }
              }
            }}
          >
            <MenuItem disabled value="">
              <em>{languageContext.values.onBoarding.sexe[languageContext.language]}</em>
            </MenuItem>
            {sexes.map((option, key) => (
              <MenuItem value={option.value} key={key}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StyledTextField
          fullWidth
          type="date"
          InputLabelProps={{ shrink: true }}
          inputProps={{
            style: { color: age === "" ? 'rgba(0,0,0,0.65)' : '' },
          }}
          label={languageContext.values.onBoarding.age[languageContext.language]}
          variant="outlined"
          value={age}
          onChange={(e) => { setAge(e.target.value); setRegisterError({}); }}
        />
        <StyledTextField
          fullWidth
          label={languageContext.values.onBoarding.poste[languageContext.language]}
          variant="outlined"
          value={poste}
          onChange={(e) => { setPoste(e.target.value); setRegisterError({}); }}
        />
        <StyledButton
          disabled={submittingRegister === true || sexe === "" || age === "" || poste === "" || poste.length < 3}
          fullWidth
          variant="contained"
          sx={{marginTop:"10px"}}
          onClick={() => {
            handleOnboarding()
          }}
        >
          {languageContext.values.register.formValid[languageContext.language]}
        </StyledButton>
      </>
    </Layout>
  )
}


const mapStateToProps = (state) => {
  return {
    formation: state.formation,
    auth: state.auth,
    user: state.user,
  }
}

export default connect(mapStateToProps, { onBoard, logoutUser })(Onboarding)
