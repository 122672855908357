import {
  ADMIN_PLAYER_FORMATION,
  ADMIN_PLAYER_LANDING,
  ADMIN_PLAYER_INDEX,
  ADMIN_PLAYER_BUG,
  ADMIN_PLAYER_HAS_CHANGE,
} from './types'

import { setAdminFormation, updateLandingPage } from './adminActions'

export const updateLanding =
  (landing, isInit = false) =>
  (dispatch) => {
    dispatch({
      type: ADMIN_PLAYER_LANDING,
      data: landing,
    })
    dispatch({ type: ADMIN_PLAYER_BUG })
    if (!isInit) dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: true })
  }

export const updateFormation =
  (formation, isInit = false) =>
  (dispatch) => {
    //console.log(formation)
    dispatch({
      type: ADMIN_PLAYER_FORMATION,
      data: formation,
    })
    dispatch({ type: ADMIN_PLAYER_BUG })
    if (!isInit) dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: true })
  }

export const updateIndex = (index) => (dispatch) => {
  dispatch({
    type: ADMIN_PLAYER_INDEX,
    data: index,
  })
}

export const onFormationChange =
  (type, data, index = null, formation, isLanding = false) =>
  (dispatch) => {
    if (isLanding) {
      if (index) {
        formation.experiences[index.iSeq][type] = data
      } else {
        formation[type] = data
      }
      dispatch({
        type: ADMIN_PLAYER_LANDING,
        data: formation,
      })
    } else {
      if (index) {
        if (index.iPart == null) {
          formation.chapitre[index.iChap][type] = data
        } else if (index.iSeq == null) {
          formation.chapitre[index.iChap].partie[index.iPart][type] = data
        } else {
          formation.chapitre[index.iChap].partie[index.iPart].sequence[index.iSeq][type] = data
        }
      } else {
        formation[type] = data
      }

      dispatch({
        type: ADMIN_PLAYER_FORMATION,
        data: formation,
      })
    }

    dispatch({ type: ADMIN_PLAYER_BUG })
    dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: true })
  }

export const addFormat =
  (format, index, formation, isLanding = false) =>
  (dispatch) => {
    if (isLanding) {
      formation.experiences.splice(index.iSeq, 0, format)
      dispatch({
        type: ADMIN_PLAYER_LANDING,
        data: formation,
      })
      updateLandingPage(formation)(dispatch)
    } else {
      formation.chapitre[index.iChap].partie[index.iPart].sequence.splice(index.iSeq, 0, format)
      dispatch({
        type: ADMIN_PLAYER_FORMATION,
        data: formation,
      })
      setAdminFormation(formation)(dispatch)
    }

    dispatch({
      type: ADMIN_PLAYER_INDEX,
      data: index,
    })

    dispatch({ type: ADMIN_PLAYER_BUG })
    dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: true })
  }

export const addPartie =
  (index, formation, partie = null) =>
  (dispatch) => {
    index.iSeq = 0
    formation.chapitre[index.iChap].partie.splice(
      index.iPart,
      0,
      partie == null ? newPartie() : partie
    )
    dispatch({
      type: ADMIN_PLAYER_FORMATION,
      data: formation,
    })
    dispatch({
      type: ADMIN_PLAYER_INDEX,
      data: index,
    })

    setAdminFormation(formation)(dispatch)

    dispatch({ type: ADMIN_PLAYER_BUG })
    dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: true })
  }

export const addChapitre =
  (iChap, indexIChap, formation, chapitre = null) =>
  (dispatch) => {
    formation.chapitre.splice(iChap, 0, chapitre == null ? newChapitre() : chapitre)
    dispatch({
      type: ADMIN_PLAYER_FORMATION,
      data: formation,
    })
    dispatch({
      type: ADMIN_PLAYER_INDEX,
      data: { iChap: indexIChap, iPart: 0, iSeq: 0 },
    })

    setAdminFormation(formation)(dispatch)

    dispatch({ type: ADMIN_PLAYER_BUG })
    dispatch({ type: ADMIN_PLAYER_HAS_CHANGE, data: true })
  }

function newFormat() {
  return {
    //isDone: false,
    type: 'Titre',
    title: 'Neuro-quizz',
    subTitle: 'A la découverte de son potentiel d’apprentissage',
    icon: 'https://video.thelab.risinguparis.com/brain.svg',
    button: { type: 'primary', text: 'Suivant' },
  }
}

function newPartie() {
  return {
    //isDone: false,
    title: 'Ecrire le titre ici',
    sequence: [newFormat()],
  }
}

function newChapitre() {
  return {
    time: 0,
    //isDone: false,
    title: 'Ecrire ici',
    partie: [newPartie()],
  }
}
